import React from "react";

const TermsAndConditions = () => {
	return (
		<div className="cws-container pt-12 pb-8">
			<h3 className="text-center font-bold text-gray-800 mb-3">Use of Co-Working Spaces</h3>
			<h6 className="text-gray-800 text-center font-bold">Terms of Use</h6>
			<div className="mt-4">
				<p className="mt-4 text-black">1. Acceptance of Terms.</p>
				<p className="mt-2 text-black leading-loose">
					The services Intrepid Spaces provides to you (including but not limited to use
					of office space and access to Internet), are subject to the following TOU.
					Intrepid Spaces reserves the right to update the TOU at any time. Intrepid
					Spaces will attempt to contact you to notify you of any updates within 30 days
					of their operation using the contact information provided on registration.
				</p>
			</div>
			<div className="mt-4">
				<p className="mt-4 text-black">2. Description of Services.</p>
				<p className="mt-2 text-black leading-loose">
					Intrepid Spaces may provide you with access to office space, work stations,
					Internet access, office equipment, meeting space, event space, knowledge
					resources, and other services as Intrepid Spaces may provide from time to time
					(collectively, “Services”). The Services shall be provided 5-6 days a week
					except Sundays and Public Holidays. The Services at all times are subject to the
					TOU.
				</p>
			</div>
			<div className="mt-4">
				<p className="mt-4 text-black">3. Terms of Payment.</p>
				<p className="mt-2 text-black leading-loose">
					For your use of the Services you will pay the full fees in accordance with the
					scale of charges. In the event of late payment, Intrepid Spaces reserves the
					right to charge you a late fee or terminate your access to use the
					Serviceswithout notice, unless advised otherwise.
					<div className="mt-3"></div>
					You will be invoiced in advance based on your usage option. Also included are
					variable charges such as telephony usage that may have been incurred during the
					previous period. Payment is required in advance of any period, at the date
					specified in the invoice. Payment for casual usage is either paid on the day of
					use unless other arrangements have been made with Intrepid Spaces.
				</p>
			</div>
			<div className="mt-4">
				<p className="mt-4 text-black">4. No Unlawful or Prohibited Use.</p>
				<p className="mt-2 text-black leading-loose">
					As a condition of your use of the Services, you will not use the Services for
					any purpose that is unlawful or prohibited by these terms, conditions and
					notices. You may not use the Services in any manner that could damage, disable,
					overburden, or impair any Intrepid Spaces server, or the network(s) connected to
					any Intrepid Spaces server, or interfere with any other party’s use and
					enjoyment of any Services. You may not attempt to gain unauthorised access to
					any Services, or accounts, computer systems or networks connected to any
					Intrepid Spaces server or to any of the Services, through hacking, password
					mining or any other means. You may not obtain or attempt to obtain any materials
					or information through any means not intentionally made available through the
					Services. You hereby represent and warrant that you have all requisite legal
					power and authority to enter into and abide by the terms and conditions of this
					TOU and no further authorisation or approval is necessary. You further represent
					and warrant that your participation or use of the Services will not conflict
					with or result in any breach of any license, contract, agreement or other
					instrument or obligation to which you are a party.
				</p>
			</div>
			<div className="mt-4">
				<p className="mt-4 text-black">4. No Unlawful or Prohibited Use.</p>
				<p className="mt-2 text-black leading-loose">
					As a condition of your use of the Services, you will not use the Services for
					any purpose that is unlawful or prohibited by these terms, conditions and
					notices. You may not use the Services in any manner that could damage, disable,
					overburden, or impair any Intrepid Spaces server, or the network(s) connected to
					any Intrepid Spaces server, or interfere with any other party’s use and
					enjoyment of any Services. You may not attempt to gain unauthorised access to
					any Services, or accounts, computer systems or networks connected to any
					Intrepid Spaces server or to any of the Services, through hacking, password
					mining or any other means. You may not obtain or attempt to obtain any materials
					or information through any means not intentionally made available through the
					Services. You hereby represent and warrant that you have all requisite legal
					power and authority to enter into and abide by the terms and conditions of this
					TOU and no further authorisation or approval is necessary. You further represent
					and warrant that your participation or use of the Services will not conflict
					with or result in any breach of any license, contract, agreement or other
					instrument or obligation to which you are a party.
				</p>
			</div>
			<div className="mt-4">
				<p className="mt-4 text-black">5. Use of services.</p>
				<p className="mt-2 text-black leading-loose">
					You agree that when participating in or using the Services, you will not:
					<br />
					a. Use the Services in connection with financial crimes, hate speech, contests,
					pyramid schemes, chain letters, junk email, spamming or any duplicative or
					unsolicited message (commercial or otherwise);
					<br />
					b. Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights
					(such as rights of privacy and publicity) of others;
					<br />
					c. Publish, post, upload, distribute or disseminate any inappropriate, profane,
					defamatory, obscene, indecent or unlawful topic, name, material or information
					on or through Intrepid Spaces Services;
					<br />
					d. Upload, or otherwise make available, files that contain images, photographs,
					software or other material protected by intellectual property laws, including,
					by way of example, and not as limitation, copyright or trademark laws (or by
					rights of privacy or publicity) unless you own or control the rights thereto or
					have received all necessary consent to do the same;
					<br />
					e. Use any material or information, including images or photographs, which are
					made available through the Services in any manner that infringes any copyright,
					trademark, patent, trade secret, or other proprietary right of any party;
					<br />
					f. Upload files that contain viruses, trojan horses, worms, time bombs,
					cancelbots, corrupted files, or any other similar software or programs that may
					damage the operation of another’s computer or property of another;
					<br />
					g. Download any file(s) that you know, or reasonably should know, cannot be
					legally reproduced, displayed, performed, and/or distributed in such manner;
					<br />
					h. Restrict or inhibit any other user from using and enjoying the Services;
					<br />
					i. Violate any code of conduct of other guidelines which may be applicable for
					any particular Service;
					<br />
					j. Harvest or otherwise collect information about others, including email
					addresses, without the authorization or consent of the disclosing party;
					<br />
					k. Create a false identity for the purpose of misleading others; and
					<br />
					l.Use the services for any illegal, misleading or antisocial purpose.
				</p>
			</div>
			<div className="mt-4">
				<p className="mt-4 text-black">
					6. Lawful Report and Stoppage of Illegal, Misleading or Antisocial Activities
				</p>
				<p className="mt-2 text-black leading-loose">
					Intrepid Spaces reserves the right at all times to disclose any information
					about you, your participation in and use of the Services as Intrepid Spaces
					deems necessary to satisfy any applicable law, regulation, legal process or
					governmental request, or to edit, refuse to post or to remove any information or
					materials, in whole or in part, in Intrepid Spaces’ sole discretion.
				</p>
			</div>
			<div className="mt-4">
				<p className="mt-4 text-black">7. Confidentiality.</p>
				<p className="mt-2 text-black leading-loose">
					a. You acknowledge and agree that during your participation in and use of the
					Services you may be exposed to Confidential Information. “Confidential
					Information” shall mean all information, in whole or in part, that is disclosed
					by Intrepid Spaces, or any participant or user of the Services or any employee,
					affiliate, or agent thereof that is nonpublic, confidential or proprietary in
					nature.
					<div className="mt-3"></div>
					Confidential Information also includes, without limitation, information about
					business, sales, operations, know-how, trade secrets, technology, products,
					employees, customers, marketing plans, financial information, services, business
					affairs, any knowledge gained through examination or observation of or access to
					the facilities, computer systems and/or books and records of Intrepid Spaces any
					analyses, compilations, studies or other documents prepared by Intrepid Spaces
					or otherwise derived in any manner from the Confidential Information and any
					information that you are obligated to keep confidential or know or has reason to
					know should be treated as confidential.
					<br />
					b. Your participation in and/or use of the Services obligates you to:
					<br />
					1. maintain all Confidential Information in strict confidence.
					<br />
					2. not to disclose Confidential Information to any third parties.
					<br />
					3. not to use the Confidential Information in any way directly or indirectly
					detrimental to Intrepid Spaces or any participant or user of the Services.
					<br />
					c. All Confidential Information remains the sole and exclusive property of
					Intrepid Spaces or the respective disclosing party. You acknowledge and agree
					that nothing in this TOU or your participation or use of the Services will be
					construed as granting any rights to you, by license or otherwise, in or to any
					Confidential Information or any patent, copyright or other intellectual property
					or proprietary rights of Intrepid Spaces or any participant or user of the
					Services.
				</p>
			</div>
			<div className="mt-4">
				<p className="mt-4 text-black">8. Participation In or Use of Services.</p>
				<p className="mt-2 text-black leading-loose">
					You acknowledge that you are participating in or using the Services at your own
					free will and decision. You acknowledge that Intrepid Spaces does not have any
					liability with respect to your access, participation in, use of the Services, or
					any loss of information resulting from such participation or use.
				</p>
			</div>
			<div className="mt-4">
				<p className="mt-4 text-black">9. Indemnification & Limited Liability.</p>
				<p className="mt-2 text-black leading-loose">
					Customers understand that if someone or something at Intrepid Spaces gets hurt
					or damaged as a result of Customers’ own negligence, then the Customers are
					responsible to pay for that injury or damage.
					<div className="mt-3"></div>
					In no event shall Intrepid Spaces or its employees, shareholders,
					representatives or guests be liable for any direct, special, incidental,
					indirect, punitive, consequential or other damages whatsoever (including, but
					not limited to, damages for: loss of profits, loss of confidential or other
					information, business interruption, personal injury, loss of privacy, failure to
					meet any duty, negligence, and any other pecuniary or other loss whatsoever,
					arising out of or in any way related to the participation in or inability to
					participate in or use of the services, the provision of or failure to provide
					services, or otherwise under or in connection with any provision of this
					agreement.
					<div className="mt-3"></div>
					Customers are exclusively responsible for their personal belongings and agree
					not to hold the Intrepid Spaces or its employees, shareholders or
					representatives, jointly or individually, for the loss, theft, or damage of
					their personal belongings including but not limited to; cash, laptops,
					computers, cell-phones, bags, wallets etc.
				</p>
			</div>
			<div className="mt-4">
				<p className="mt-4 text-black">10. Renewal & Termination of Usage.</p>
				<p className="mt-2 text-black leading-loose">
					The Services are automatically terminated at the end of each period where no
					renewal request/payment is made by the user. The TOU must be adhered to at all
					times. Failure to follow the TOU can result in non-renewal or even early
					termination of the service. Intrepid Spacesreserves the right to terminate the
					Services at any time, immediately and without notice, if you fail to comply with
					theTOU. This includes non-payment or violation of this TOU and related rules. If
					this happens, Intrepid Spaceswill refund any amounts paid for unused periods
					that remain after deducting any pending charges, on a prorata basis. If the
					Services are used for any illegal, misleading or antisocial purpose activities
					no refund is payable irrespective of unused periods that remain.
					<div className="mt-3"></div>
					You may terminate this Agreement by giving a written notice of termination as
					established in this TOU. Termination shall be in effect as of the end of that
					week or calendar month.
				</p>
			</div>
			<div className="mt-4">
				<p className="mt-4 text-black">11. Severability.</p>
				<p className="mt-2 text-black leading-loose">
					In the event that any provision or portion of this TOU is determined to be
					invalid, illegal or unenforceable for any reason, in whole or in part, the
					remaining provisions of this TOU shall be unaffected thereby and shall remain in
					full force and effect to the fullest extent permitted by applicable law.
				</p>
			</div>
			<div className="mt-4">
				<p className="mt-4 text-black">12. Insurance</p>
				<p className="mt-2 text-black leading-loose">
					We maintain aninsurance policy that covers the Space and we carry our own
					contents insurance. Our contents insurance does not extend to your property or
					the property of your guests. You are not required but it is strongly suggested
					that you carry a renter’s insurance policy to cover your own equipment while
					using the Services. That policy may cover your current residence/office, as well
					as the premises of Intrepid Spaces.
				</p>
			</div>
			<div className="mt-4">
				<p className="mt-4 text-black">13. Non-Disparagement</p>
				<p className="mt-2 text-black leading-loose">
					You shall, during and after the participation in and use of the Services,
					refrain from making any statements or comments of a defamatory or disparaging
					nature to any third party regarding Intrepid Spaces, or any of Intrepid spaces’
					officers, directors, employees, personnel, agents, policies, services or
					products, other than to comply with law. Constructive criticism remains welcome
				</p>
			</div>
			<div className="mt-4">
				<p className="mt-4 text-black">14. Activation of the Services</p>
				<p className="mt-2 text-black leading-loose">
					In order to activate and use the Services you are required to provide to the
					satisfaction of Intrepid Spaces: <br />
					<br />• the full name, address and telephone number of all persons, natural and
					corporate that will use the Services; and <br />
					<br />• two (2) original, verifiable forms of identification, or suitably
					certified copies, for all persons that will use the Services. The form of
					identification must be of a type approved by Intrepid Spaces, including a ‘proof
					of identity’ and a ‘proof of address’, and <br />
					<br />• the nature of business to be undertaken, or reason where rental is not
					for business purposes. A business can be defined as an activity involving the
					sale of goods and/or services.
				</p>
			</div>
			<p className="mt-4 text-center font-bold leading-loose">
				I hereby acknowledge that I have read and understood all of the terms and conditions
				contained in this TOU and further agree to be bound to the TOU regarding my
				participation in and use of the Services.
			</p>
		</div>
	);
};

export default TermsAndConditions;
