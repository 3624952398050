import React from "react";
import { Link } from "react-router-dom";
import Img from "../../../assets/images/intrepid-logo-1.png";
import Person from "../../../assets/images/default-avatar.jpg";
import { ReactComponent as Bell } from "../../../assets/vectors/bell.svg";
import { ReactComponent as Search } from "../../../assets/vectors/Search-Icon.svg";
import { ExtractAuthContext } from "../../../context/auth-context";

const AdminHeader = ({ shouldShow, setShouldShow }) => {
	const { state } = ExtractAuthContext();
	// console.log(state)
	const fullName = state?.user?.first_name + " " + state?.user?.last_name;
	return (
		<nav className="p-4 px-20 mobiles:px-6 w-full fixed top-0 left-0 right-0 z-50 bg-white flex border-b-2 border items-center justify-between">
			<Link to="/admin">
				<img
					src={require("../../../assets/vectors/spaces-logo-intrepid.svg").default}
					className="hidden mobiles:block h-10"
					alt=""
				/>
				<img
					src={require("../../../assets/vectors/spaces-logo-intrepid.svg").default}
					className="block mobiles:hidden h-12"
					alt=""
				/>
			</Link>
			<article className="flex items-center">
				{/* <div className="admin-gradient-border">
					<Search />
					<input
						type="search"
						name=""
						placeholder="search e.g card"
						className="py-2 pl-14 pr-5 text-sm focus:outline-none block admin-input-br"
						id=""
					/>
				</div> */}
				<div className="flex ml-10 items-center">
					<img
						src={Person}
						className="block mr-3 mobiles:hidden w-8 h-8 rounded-full"
						alt=""
					/>
					<div>
						<small className="text-xs text-gray-500">Welcome,</small>
						<p className="text-sm text-gray-500">{fullName}</p>
					</div>
				</div>
				<div
					onClick={() => {
						setShouldShow(!shouldShow);
					}}
					className={`w-8 ml-10 h-5 tablets:block harmburger ${
						shouldShow ? "active" : ""
					} relative hidden cursor-pointer`}
				>
					<span className="w-full line1 bg-gray-600 absolute block"></span>
					<span className="w-2/5 ml-auto line2 bg-gray-600 absolute block"></span>
					<span className="w-full absolute bg-gray-600 line3 block"></span>
				</div>
				<div className="relative mobiles:hidden ml-10 admin-bell">
					<Bell />
					<span className="w-5 h-5 rounded-full flex justify-center items-center absolute admin-gradient-bg">
						<p style={{ fontSize: "9px" }} className="text-white">
							0
						</p>
					</span>
				</div>
			</article>
		</nav>
	);
};

export default AdminHeader;
