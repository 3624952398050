import React from "react";
import { Link } from "react-router-dom";
const AdminSidebarUnit = ({ activeLink, value, Component, text, to }) => {
	// console.log(activeLink);
	return (
		<Link
			// onClick={() => {
			// 	setActiveLink(value);
			// }}
			to={to}
		>
			<li
				className={`${
					activeLink === value ? "admin-gradient-bg-link" : "bg-transparent"
				} pl-20 py-4 flex items-center`}
			>
				{/* <SvgImi active={true} /> */}
				<Component width="20px" height="20px" active={activeLink === value} />
				<span
					className={`ml-4 text-sm ${
						activeLink === value ? "text-white" : "admin-gradient-bg-text"
					}`}
				>
					{text}
				</span>
			</li>
		</Link>
	);
};

export default AdminSidebarUnit;
