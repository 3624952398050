import React, { lazy, Suspense } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import VerifyEmail from "../../component/auth/verity-email";
import Loader2 from "../../component/building-block/loader2";
import ForgotPassword from "../../pages/auth/forgot-password";
import LoginRegister from "../../pages/auth/login-register";
import AdminRouteComponent from "../route-component/admin-route-component";
import CustomerRouteComponent from "../route-component/customer-route-component";
import { PrivateRoute } from "../route-component/protected-route";
import { PasswordReset } from "../../pages/auth/reset-password";
import TermsOfUse from "../../pages/TermsOfUse";
import TermsAndConditions from "../../pages/TermsAndConditions";

// const VerifyEmail = lazy(() => import("../../component/auth/verity-email"));
// const ForgotPassword = lazy(() => import("../../pages/auth/forgot-password"));
// const LoginRegister = lazy(() => import("../../pages/auth/login-register"));
// const AdminRouteComponent = lazy(() =>
// 	import("../route-component/admin-route-component")
// );
// const CustomerRouteComponent = lazy(() =>
// 	import("../route-component/customer-route-component")
// );

const Routes = () => {
	return (
		<Router>
			<Switch>
				<Suspense
					fallback={
						<div
							style={{ height: "80vh" }}
							className="flex items-center justify-center"
						>
							<Loader2 />
						</div>
					}
				>
					<Route path="/terms-and-conditions" exact component={TermsOfUse} />
					<Route path="/terms-of-use" exact component={TermsAndConditions} />
					<Route
						path="/verify-transaction"
						exact
						component={lazy(() => import("../../pages/verify-transaction"))}
					/>
					<Route path="/verify/:data" component={VerifyEmail} />
					<Route path="/forgot-password" component={ForgotPassword} />
					<Route path="/password/reset/:data" component={PasswordReset} />
					<Route path="/login-register" component={LoginRegister} />

					{/* <Route path="/admin" component={AdminRouteComponent} /> */}
					<PrivateRoute path="/admin" component={AdminRouteComponent} />
					<Route
						path={["/booking", "/choose-a-plan", "/"]}
						exact
						component={CustomerRouteComponent}
					/>
					{/*<Route*/}
					{/*    path={"/customer"}*/}
					{/*    component={lazy(() =>*/}
					{/*        import("../../pages/customer/dashboard/dashboard")*/}
					{/*    )}*/}
					{/*/>*/}
					<PrivateRoute
						path={"/customer"}
						exact
						component={lazy(() => import("../../pages/customer/dashboard/dashboard"))}
					/>
					<PrivateRoute
						path={"/customer/bookings"}
						component={lazy(() =>
							import("./../../pages/customer/dashboard/transaction-history")
						)}
					/>
					<PrivateRoute
						path={"/customer/buy-addons"}
						component={lazy(() =>
							import("./../../pages/customer/dashboard/buy_addons")
						)}
					/>
					<PrivateRoute
						path={"/customer/logout"}
						component={lazy(() => import("../../pages/customer/dashboard/logout"))}
					/>
				</Suspense>
			</Switch>
		</Router>
	);
};

export default Routes;
