import "./App.css";
import "./stylesheet/css/index.css";
import "./stylesheet/css/tailwind-output.css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import React, { useEffect } from "react";

import { toast, ToastContainer } from "react-toastify";

import { checkLoggedInUser } from "./Api/api-request";
import { ExtractAuthContext } from "./context/auth-context";
import Routes from "./layout/routes/routes";
import {
	removeExpiryDateFromStorage,
	removeTokenFromStorage,
	storageContainsToken,
	tokenStillValid,
} from "./utils/helpers";

function App() {
	const { execute } = ExtractAuthContext();
	useEffect(() => {
		if (storageContainsToken()) {
			if (tokenStillValid()) {
				const fetchUser = async () => {
					try {
						const response = await checkLoggedInUser();
						if (response.status === 200) {
							execute(response.data.data);
						}
						console.log(response);
					} catch (error) {
						if (!error.response) {
							toast.error("Kindly check your network and refresh this page!");
						}
					}
				};
				fetchUser();
			} else {
				removeExpiryDateFromStorage();
				removeTokenFromStorage();
			}
		}
	}, []);

	return (
		<div className="App w-screen">
			<ToastContainer
				position="top-right"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<Routes />
		</div>
	);
}

export default App;
