import React from 'react';

const LoginRegisterTab = ({ tab, setTab }) => {
    return (
        <article
            style={{
                borderTopLeftRadius: "0.375rem",
                borderTopRightRadius: "0.375rem",
            }}
            className="w-full flex"
        >
            <div
                style={{
                    borderTopLeftRadius: "0.3755rem",
                }}
                onClick={() => {
                    setTab("login");
                }}
                className="w-1/2 cursor-pointer bg-white"
            >
                <div
                    style={{
                        borderTopLeftRadius: "0.3755rem",
                        borderRightWidth: "1px",
                        borderRightColor: "#e5e7eb",
                    }}
                    className="w-full text-center py-6"
                >
                    Login
                </div>
                <div
                    className={`${
                        tab === "login" ? "admin-gradient-bg" : ""
                    } h-1`}
                ></div>
            </div>

            <div
                style={{
                    borderTopRightRadius: "0.3755rem",
                }}
                onClick={() => {
                    setTab("register");
                }}
                className="w-1/2 cursor-pointer bg-white"
            >
                <div
                    style={{
                        borderTopRightRadius: "0.3755rem",
                        borderLeftWidth: "1px",
                        borderLeftColor: "#e5e7eb",
                    }}
                    className="w-full bg-white text-center py-6"
                >
                    Register
                </div>
                <div
                    className={`${
                        tab === "login" ? "" : "admin-gradient-bg"
                    } h-1`}
                ></div>
            </div>
        </article>
    );
};

export default LoginRegisterTab;
