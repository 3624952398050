import React from "react";

// import { logo } from "../../../../assets/vectors/check-circle-regular.svg";
import { ReactComponent as Logo } from "../../../../assets/vectors/circle_checked.svg";

const PlanCard = ({ plan, onSelect, selected, summaryRef }) => {
	const { options } = plan;

	const shouldShow = options.length > 0;

	const features = options.map((each) => {
		if (each.name === "Maximum Attendees") {
			const attendeesNumber = each.pivot.description;
			return `${attendeesNumber} ${each.name}`;
		}
		return each.name;
	});
	console.log(features, options);

	return (
		<article
			className={`cws-height-40 relative rounded-lg bg-gray-300 p-6 flex flex-col justify-end ${
				selected ? "border-2 border-pink-300" : ""
			}`}
		>
			<h4 className="text-2xl text-white cws-bold-font">
				{plan.name} ({plan.type})
			</h4>
			<p className="text-white">
				{plan.rate}/{plan.rate_type}
			</p>
			<div className="flex mt-2 justify-between">
				<button
					onClick={() => {
						onSelect(plan);
						summaryRef.current.scrollIntoView();
					}}
					className="cws-red-background cws-bold-font text-white cws-button-plan flex items-center justify-center"
				>
					Select
				</button>
				<span className="block cws-bold-font text-white">{plan.slot} spaces available</span>
			</div>
			{shouldShow && (
				<div
					style={{
						position: "absolute",
						top: "97%",
						left: "0px",
						borderTopLeftRadius: "0",
						borderTopRightRadius: "0",
					}}
					className="grid bg-white w-full grid-cols-2 shadow-md px-5 py-5 rounded gap-1 text-xs"
				>
					{/* <img src={logo} alt="" /> */}
					{features.map((each) => {
						return (
							<p className="text-gray-500 items-center flex">
								<Logo />
								<span className="ml-3">{each}</span>
							</p>
						);
					})}
				</div>
			)}
		</article>
	);
};

export default PlanCard;
