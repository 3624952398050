export const validateRegisterFormData = (formData) => {
	let errors = {};

	if (!formData.first_name) {
		errors.first_name = "First name is required";
	}

	if (!formData.last_name) {
		errors.last_name = "Last name is required";
	}

	if (!formData.phone_number) {
		errors.phone_number = "Phone number is required";
	} else if (formData.phone_number && formData.phone_number.length < 11) {
		errors.phone_number = "Enter valid phone number";
	}

	if (!formData.password) {
		errors.password = "Password is required";
	} else if (formData.password && formData.password.length < 6) {
		errors.password = "Password is too short";
	}

	if (!formData.password_confirmation) {
		errors.password_confirmation = "Please confirm your password";
	} else if (formData.password !== formData.password_confirmation) {
		errors.password_confirmation = "Password doesn't match";
	}

	if (!formData.email) {
		errors.email = "Email address is required";
	} else if (
		!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email)
	) {
		errors.email = "Email address is invalid";
	}

	return errors;
};
