import React from "react";
import Input2 from "../building-block/input-2";
import { Link } from "react-router-dom";
import { useLoginForm } from "../../hooks/useLoginForm";
import Loader from "../building-block/loader";

const LoginForm = ({ tab, setTab }) => {
	const {
		formData,
		handleInputChange,
		error,
		handleFormSubmit,
		isSubmitting,
	} = useLoginForm();
	return (
		<form
			onSubmit={handleFormSubmit}
			className={`${
				tab === "login" ? "" : "hidden opacity-0 h-0 m-0 p-0"
			}`}
		>
			<Input2
				name="email"
				error={error.email}
				value={formData.email}
				onChange={handleInputChange}
				type="email"
				placeholder="Email Address"
			/>
			<Input2
				name="password"
				error={error.password}
				onChange={handleInputChange}
				value={formData.password}
				type="password"
				placeholder="Password"
			/>
			<div className="mt-7 pl-2">
				<input
					type="checkbox"
					checked={formData.checked}
					onChange={handleInputChange}
					name="remember_me"
					id="login"
				/>
				<label className="ml-3 text-gray-500">Stay Logged In?</label>
			</div>
			<div className="mt-5  flex justify-end">
				<Link to="/forgot-password">
					<p className="ml-3 text-red-500">Forgot your password?</p>
				</Link>
			</div>
			<div className="mt-5 inline-block relative">
				<input
					type="submit"
					className="inline-block cursor-pointer focus:outline-none w-36 text-center py-3 px-5 admin-gradient-bg text-white rounded-3xl"
					value={isSubmitting ? "" : "Login"}
				/>

				<Loader
					style={{
						position: "absolute",
						top: "45%",
						left: "50%",
						transform: "translate(-50%,-50%)",
					}}
					show={`${
						isSubmitting ? "" : "opacity-0 force-height-zero"
					}`}
				/>
			</div>
			<div className="mt-5  flex justify-end">
				<p className="ml-3 text-gray-500">
					Don't have an account yet?{" "}
					<span
						onClick={() => {
							setTab("register");
						}}
						className="text-red-500 cursor-pointer"
					>
						Sign up here
					</span>{" "}
				</p>
			</div>
		</form>
	);
};

export default LoginForm;
