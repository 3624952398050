import React, { useRef, useState, useEffect } from "react";
import ScrollToTopOnMount from "../../component/building-block/scroll-to-top";

import CwsEverything from "../../component/views/customer/homepage/cws-everything";
import CwsFeatures from "../../component/views/customer/homepage/cws-features";
import CwsOptions from "../../component/views/customer/homepage/cws-options";
import HomeHero from "../../component/views/customer/homepage/home-hero";

const CustomerHomepage = () => {
	const optionsRef = useRef();
	const immediate = useRef(false);
	const [move, setMove] = useState(false);

	useEffect(() => {
		if (immediate.current) {
			console.log("moved");
			// const pos = optionsRef.current.scrollTop;
			// const scrollTop = document.scrollTop();
			const distance = optionsRef.current.getBoundingClientRect().top - 250;
			// const distance = elementOffset - scrollTop;

			console.log(distance);
			window.scrollTo({ top: distance, behaviour: "smooth" });
			console.log(optionsRef.current);
		}
		immediate.current = true;
	}, [move]);

	return (
		<>
			<ScrollToTopOnMount />
			<HomeHero setMove={setMove} />
			<CwsOptions optionsRef={optionsRef} />
			<CwsFeatures />
			<CwsEverything />
		</>
	);
};

export default CustomerHomepage;
