import React, { useEffect, useState } from "react";

import { Link, useHistory } from "react-router-dom";

import { ApiRequest, ApiRequestWithToken } from "../../../../Api/api-request";
import { useSingleState } from "../../../../hooks/useFetch";
import { useMutation } from "../../../../hooks/useMutation";
import { FormApiError } from "../../../building-block/form_api_error";
import { toast } from "react-toastify";
import Loader2 from "../../../building-block/loader2";
import { ExtractAuthContext } from "../../../../context/auth-context";

const BookingForm = () => {
	const history = useHistory();
	const {
		state: { user },
	} = ExtractAuthContext();
	const firstName = useSingleState(user?.first_name ?? "");
	const lastName = useSingleState(user?.last_name ?? "");
	const bookData = useSingleState({});
	const [agreed, setAgreed] = useState("");
	const email = useSingleState(user?.email ?? "");
	const phone = useSingleState(user?.phone_number ?? "");
	const callApi = useMutation();
	useEffect(() => {
		const data = localStorage.getItem("book_data");
		if (data) {
			bookData.set(JSON.parse(data));
			localStorage.removeItem("book_data");
		} else {
			history.push("/choose-a-plan");
		}
	}, []);
	const handleSubmit = (event) => {
		event.preventDefault();

		if (!agreed) {
			toast.error(
				"You need to agree with our Terms of Use, and Terms and Conditions to proceed."
			);
			return;
		}
		callApi.execute(
			() =>
				(user?.id ? ApiRequestWithToken() : ApiRequest()).post(
					"/book/" + bookData.get.room,
					{
						...bookData.get,
						first_name: firstName.get,
						last_name: lastName.get,
						email: email.get,
						phone_number: phone.get,
					}
				),
			{
				onSuccess: (data) => {
					console.log(data);
					window.location = data.data.authorization_url;
				},
			}
		);
	};
	return (
		<section id="form" className="py-20 cws-booking-form-bg">
			<div className="cws-container">
				<h5 className="text-3xl cws-bold-font text-gray-600">
					Your details
					<form onSubmit={handleSubmit} className="mt-10 cws-booking-form-width">
						<div className="grid grid-cols-2 mobiles:grid-cols-1 gap-5">
							<FormApiError name="first_name" errors={callApi.validationError}>
								<input
									required
									type="text"
									value={firstName.get}
									onChange={(e) => firstName.set(e.currentTarget.value)}
									className="bg-white cws-booking-form-input-border text-base focus:outline-none p-5 block w-full"
									placeholder="First Name"
								/>
							</FormApiError>
							<FormApiError name="last_name" errors={callApi.validationError}>
								<input
									value={lastName.get}
									onChange={(e) => lastName.set(e.currentTarget.value)}
									required
									type="text"
									className="bg-white cws-booking-form-input-border text-base focus:outline-none p-5 block w-full"
									placeholder="Last Name"
								/>
							</FormApiError>
						</div>
						<FormApiError name="email" errors={callApi.validationError}>
							<input
								value={email.get}
								onChange={(e) => email.set(e.currentTarget.value)}
								required
								type="email"
								className="bg-white mt-4 cws-booking-form-input-border text-base focus:outline-none p-5 block w-full"
								placeholder="Email"
							/>
						</FormApiError>
						<FormApiError name="phone_number" errors={callApi.validationError}>
							<input
								value={phone.get}
								onChange={(e) => phone.set(e.currentTarget.value)}
								required
								type="tel"
								className="bg-white mt-4 cws-booking-form-input-border text-base focus:outline-none p-5 block w-full"
								placeholder="Phone"
							/>
						</FormApiError>
						<div className="flex items-center">
							<input
								type="checkbox"
								id=""
								onChange={(e) => {
									setAgreed(e.target.checked);
								}}
								className="mr-2 w-4 h-4 mobiles:w-12 mobiles:h-12"
								// value={each.id}
							/>

							<p className="ml-3 leading-4 mt-4" style={{ fontSize: "12px" }}>
								I acknowledge that I have read and do hereby accept the terms and
								conditions in the Spaces's{" "}
								<Link className="text-blue-500" to="/terms-of-use" target="_blank">
									Terms of Use
								</Link>
								, and{" "}
								<Link
									className="text-blue-500"
									to="/terms-and-conditions"
									target="_blank"
								>
									Terms And Conditions
								</Link>
							</p>
						</div>

						{callApi.loading ? (
							<div className="flex justify-center mt-8">
								<Loader2 />
							</div>
						) : (
							<button
								type="submit"
								className="cws-red-background cws-button flex items-center justify-center text-center text-white text-base focus:outline-none mt-8 w-full"
							>
								PROCEED
							</button>
						)}
					</form>
				</h5>
			</div>
		</section>
	);
};

export default BookingForm;
