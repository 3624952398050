import { useState } from "react";
import { useHistory } from "react-router-dom";
import { validateLoginFormData } from "../form-validation/login-validation";
import { ExtractAuthContext } from "./../context/auth-context";
import { login } from "../Api/api-request";
import { toast } from "react-toastify";
import { setTokenToStorage, setExpiryDateToStorage } from "../utils/helpers";

export const useLoginForm = (initialState = { remember_me: false }) => {
	const [formData, setFormData] = useState(initialState);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [error, setError] = useState({});
	const { execute } = ExtractAuthContext();
	const history = useHistory();

	const handleInputChange = (e) => {
		if (e.target.name === "remember_me") {
			setFormData({ ...formData, [e.target.name]: e.target.checked });
		} else {
			setFormData({ ...formData, [e.target.name]: e.target.value });
		}
	};

	const validate = () => {
		return validateLoginFormData(formData);
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		const validationErrors = validate();
		setError(validationErrors);
		if (Object.keys(validationErrors).length > 0) {
			return;
		}
		setIsSubmitting(true);
		console.log(formData);
		const userData = formData;
		try {
			const response = await login(userData);
			if (response.status === 200) {
				toast.success("Successful");
				execute(response.data.data.user);
				setTokenToStorage(response.data.data.token);
				setExpiryDateToStorage(response.data.data.expires_at);

				history.push("/admin");
			}
			console.log(response);
		} catch (error) {
			setIsSubmitting(false);
			if (!error.response) {
				toast.error("Please! Check your network and refresh");
			} else if (error.response.data) {
				toast.error(error.response.data.message);
			} else {
				toast.error("Something went wrong! Please try again");
			}
		}
	};

	return {
		formData,
		handleInputChange,
		error,
		handleFormSubmit,
		isSubmitting,
	};
};
