import React from "react";
import CwsButton from "../../../building-block/button";

const HomeHero = ({ setMove }) => {
	return (
		<div>
			<section className="cws-home-hero-height mobiles:h-auto cws-primary-color mobiles:pb-4 mobiles:pt-10">
				<div className="cws-container items-center justify-between mobiles:block flex h-full">
					<div className="left w-1/2 mobiles:w-full">
						<h2 className="text-6xl text-gray-600 mobiles:text-5xl cws-extra-bold-font">
							{/* Co-Working Space */}
							Intrepid Spaces
						</h2>
						<p className="mt-10 text-gray-500">
							Whether it's working for yourself or remotely, it's great to be
							independent. Our purpose is to create an environment that allows you to
							focus on what's really important, getting your work done and meeting
							interesting people.All these in one place!
						</p>
						<div className="mt-10">
							<CwsButton
								to="/choose-a-plan"
								otherStyles={
									"cws-red-background cursor-pointer cws-bold-font text-white cws-red-border"
								}
							>
								View Options
							</CwsButton>
							<a
								className="bg-transparent mt-3 cursor-pointer cws-bold-font cws-red-text cws-red-border cws-button flex items-center justify-center text-center"
								href="http://intrepid.com.ng/#aboutus"
							>
								Learn about Intrepid
							</a>

							{/* <CwsButton
								to="#"
								// onClick={() => {
								// 	setMove((e) => !e);
								// }}
								otherStyles={
									"bg-transparent mt-3 cursor-pointer cws-bold-font cws-red-text cws-red-border"
								}
							>
								learn about Intrepid
							</CwsButton> */}
						</div>
					</div>
					<div className="right cws-width-47 mobiles:hidden full mobiles:mt-8 grid grid-cols-2 gap-4 cws-height-60">
						<div className="h-full flex flex-col justify-between">
							<div className="cws-background-image2 w-full cws-height-48"></div>
							<div className="cws-background-image3 w-full cws-height-48"></div>
						</div>
						<div className="cws-background-image h-full"></div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default HomeHero;
