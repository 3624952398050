import React from "react";
import ScrollToTopOnMount from "../../component/building-block/scroll-to-top";
import PlanHero from "../../component/views/customer/plan/plan-hero";
import PlanSection from "../../component/views/customer/plan/plan-section";

const ChooseAPlan = () => {
	return (
		<>
			<ScrollToTopOnMount />
			{/* <PlanHero /> */}
			<PlanSection />
		</>
	);
};

export default ChooseAPlan;
