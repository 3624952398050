import React from "react";

const Loader2 = ({ ...props }) => {
	return (
		<div {...props} className={`lds-ring2`}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
};

export default Loader2;
