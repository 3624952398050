export const validateLoginFormData = (formData) => {
	let errors = {};

	if (!formData.email) {
		errors.email = "Email address is required";
	} else if (
		!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email)
	) {
		errors.email = "Email address is invalid";
	}

	if (!formData.password) {
		errors.password = "Password is required";
	} else if (formData.password && formData.password.length < 6) {
		errors.password = "Password is too short";
	}

	return errors;
};
