import { toast } from 'react-toastify';

import { getApiErrorMessage } from '../utils/helpers';
import {
  callAsync,
  useSingleState,
} from './useFetch';

export function useMutation({ alertSuccess = true, alertError = true, prm = () => {}, onS = () => {}, onErr = () => {}} = {}) {
    const loading = useSingleState(false);
    const validationError = useSingleState({});
    const execute = (
        promise,
        { successMessage, errorMessage, onSuccess, onError } = {}
    ) => {
        loading.set(true);
        validationError.set({});
        callAsync(
            promise,
            (data) => {
                if (alertSuccess) {
                    toast.success(successMessage ?? data.message);
                }
                if (onSuccess) {
                    onSuccess(data);
                }
            },
            (error) => {
                console.log(error);
                if (alertError) {
                    toast.error(errorMessage ?? getApiErrorMessage(error));
                }
                if (error?.data?.errors) {
                    validationError.set(error.data.errors);
                }
                if (onError) {
                    onError(error?.data);
                }
            },
            () => loading.set(false)
        );
    };
    const trigger = () => {
        execute(prm, {onSuccess: onS, onError: onErr} )
    }
    return {
        execute,
        trigger,
        loading: loading.get,
        validationError: validationError.get,
    };
}
