import React from "react";
import { Redirect, Route } from "react-router-dom";
import { storageContainsToken, tokenStillValid } from "../../utils/helpers";

export const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			storageContainsToken() && tokenStillValid() ? (
				<Component {...props} />
			) : (
				<Redirect to="/login-register" />
			)
		}
	/>
);
