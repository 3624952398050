export function FormApiError({ errors, name, children }) {
    return (
        <div className={`form-group ${errors?.[name] ? "has-error" : ""}`}>
            {children}
            {errors?.[name] && (
                <span className="form-error">
                    {errors?.[name]?.[0] ?? errors?.[name]}
                </span>
            )}
        </div>
    );
}
