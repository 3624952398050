import React from "react";

const Input = ({
	label = false,
	placeholder,
	onChange,
	error = false,
	name,
	type,
	fs = "14px",
	...props
}) => {
	return (
		<div>
			<label
				className={`inline-block ${
					label
						? "visible p-4 py-0 text-gray-400"
						: "hidden opacity-0 h-0 m-0 p-0"
				}`}
			>
				{label}
			</label>
			<input
				{...props}
				onChange={onChange}
				placeholder={placeholder}
				type={type}
				name={name}
				className="mt-3 px-5 pl-8 admin-input focus:outline-none border border-gray-200 block w-full"
			/>
			<span
				className={`inline-block ${
					error
						? "visible p-4 py-2 text-red-600"
						: "hidden opacity-0 h-0 m-0 p-0"
				}`}
			>
				{error}
			</span>
		</div>
	);
};

export default Input;
