import React from 'react';

import ScrollToTopOnMount from '../../component/building-block/scroll-to-top';
import BookingForm from '../../component/views/customer/booking/booking-form';
import BookingHero from '../../component/views/customer/booking/booking-hero';
import BookingPayment
  from '../../component/views/customer/booking/booking-payment';

const CustomerBooking = () => {
    ScrollToTopOnMount();
    return (
        <>
            <BookingHero />
            <BookingForm />
            <BookingPayment />
        </>
    );
};

export default CustomerBooking;
