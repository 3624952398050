import React, { useState } from "react";
import Input2 from "../building-block/input-2";
import { useForm } from "../../hooks/useForm";
import Loader from "../building-block/loader";
// import { register } from "../../Api/api-request";

const RegisterForm = ({ tab }) => {
	const {
		formData,
		handleInputChange,
		resetForm,
		errors,
		isSubmitting,
		handleSubmit,
	} = useForm();

	return (
		<form
			onSubmit={handleSubmit}
			className={`${
				tab === "login" ? "hidden opacity-0 h-0 m-0 p-0" : "pb-16"
			}`}
		>
			<Input2
				onChange={handleInputChange}
				error={errors.first_name}
				name="first_name"
				type="text"
				placeholder="First Name"
				value={formData.first_name}
			/>
			<Input2
				onChange={handleInputChange}
				error={errors.last_name}
				name="last_name"
				type="text"
				placeholder="Last Name"
				value={formData.last_name}
			/>
			<Input2
				onChange={handleInputChange}
				error={errors.other_name}
				name="other_name"
				type="text"
				placeholder="Other Name"
				value={formData.other_name}
			/>
			<Input2
				onChange={handleInputChange}
				error={errors.email}
				name="email"
				type="email"
				placeholder="Email Address"
				value={formData.email}
			/>
			<Input2
				onChange={handleInputChange}
				error={errors.password}
				name="password"
				type="password"
				placeholder="Password"
				value={formData.password}
			/>
			<Input2
				error={errors.password_confirmation}
				onChange={handleInputChange}
				name="password_confirmation"
				type="password"
				placeholder="Repeat password"
				value={formData.password_confirmation}
			/>
			<Input2
				onChange={handleInputChange}
				error={errors.phone_number}
				value={formData.phone_number}
				name="phone_number"
				type="tel"
				placeholder="Phone Number"
			/>

			<div className="mt-8 relative flex justify-center">
				<input
					type="submit"
					className="inline-block focus:outline-none w-36 cursor-pointer text-center py-3 px-5 admin-gradient-bg text-white rounded-3xl"
					value={isSubmitting ? "" : "Submit"}
				/>
				<Loader
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%,-50%)",
					}}
					show={`${
						isSubmitting ? "" : "opacity-0 force-height-zero"
					}`}
				/>
			</div>
		</form>
	);
};

export default RegisterForm;
