import React from "react";

const Loader = ({ show = "", ...props }) => {
	return (
		<div {...props} className={`lds-ring ${show}`}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
};

export default Loader;
