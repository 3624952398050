import React from "react";

import { Link } from "react-router-dom";

import Arrow from "../../../../assets/vectors/arrow";

const CwsCard = ({ data, ...props }) => {
	return (
		<Link
			to={"/choose-a-plan?room=" + data.id}
			id="options"
			{...props}
			className="p-8 pt-20 cws-box-shadow"
		>
			<h5 className="text-2xl cws-bold-font text-gray-500">{data.heading}</h5>
			<p className="mt-3 leading-tight cws-height-20">{data.text}</p>
			<p className="mb-16">{data.price}</p>
			<div className="flex pr-3 justify-end">
				<span>
					<Arrow />
				</span>
			</div>
		</Link>
	);
};

export default CwsCard;
