import React from "react";

const Payment = ({ active, width, height }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 17 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.7175 8.35877V10.8117C15.7175 11.4862 15.1656 12.0382 14.4911 12.0382H2.22646C1.55191 12.0382 1 11.4862 1 10.8117V2.22646C1 1.55191 1.55191 1 2.22646 1H14.4911C15.1656 1 15.7175 1.55191 15.7175 2.22646V4.67938"
				stroke={`${active ? "#ffffff" : "url(#paint0_linear)"}`}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M4.06653 1V12.0382"
				stroke={`${active ? "#ffffff" : "url(#paint1_linear)"}`}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M9.95286 4.67982H16.3305V8.3592H9.95286C8.91037 8.17523 8.35846 7.562 8.35846 6.51951C8.35846 5.47702 8.91037 4.86379 9.95286 4.67982Z"
				stroke={`${active ? "#ffffff" : "url(#paint2_linear)"}`}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10.1984 6.51854H10.8116"
				stroke={`${active ? "#ffffff" : "url(#paint3_linear)"}`}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M15.7175 8.35877V10.8117C15.7175 11.4862 15.1656 12.0382 14.4911 12.0382H2.22646C1.55191 12.0382 1 11.4862 1 10.8117V2.22646C1 1.55191 1.55191 1 2.22646 1H14.4911C15.1656 1 15.7175 1.55191 15.7175 2.22646V4.67938"
				stroke={`${active ? "#ffffff" : "url(#paint0_linear)"}`}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M4.06653 1V12.0382"
				stroke={`${active ? "#ffffff" : "url(#paint1_linear)"}`}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M9.95286 4.67982H16.3305V8.3592H9.95286C8.91037 8.17523 8.35846 7.562 8.35846 6.51951C8.35846 5.47702 8.91037 4.86379 9.95286 4.67982Z"
				stroke={`${active ? "#ffffff" : "url(#paint2_linear)"}`}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10.1984 6.51854H10.8116"
				stroke={`${active ? "#ffffff" : "url(#paint3_linear)"}`}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>{" "}
			<defs>
				<linearGradient
					id="paint0_linear"
					x1="8.35877"
					y1="1"
					x2="6.18485"
					y2="18.6509"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#D4165E" />
					<stop offset="1" stop-color="#2317B1" />
				</linearGradient>
				<linearGradient
					id="paint1_linear"
					x1="4.56653"
					y1="1"
					x2="-3.01224"
					y2="5.18107"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#D4165E" />
					<stop offset="1" stop-color="#2317B1" />
				</linearGradient>
				<linearGradient
					id="paint2_linear"
					x1="12.3445"
					y1="4.67982"
					x2="11.8943"
					y2="10.6186"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#D4165E" />
					<stop offset="1" stop-color="#2317B1" />
				</linearGradient>
				<linearGradient
					id="paint3_linear"
					x1="10.505"
					y1="6.51854"
					x2="10.0994"
					y2="8.03326"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#D4165E" />
					<stop offset="1" stop-color="#2317B1" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default Payment;
