import React from "react";
import { ReactComponent as Master } from "../../../../assets/vectors/mastercard.svg";
import { ReactComponent as Visa } from "../../../../assets/vectors/visa.svg";
import { ReactComponent as Paystack } from "../../../../assets/vectors/Paystack Logo.svg";
import { ReactComponent as Verve } from "../../../../assets/vectors/Verve Logo.svg";

const BookingPayment = () => {
	return (
		<section className="py-16 bg-white">
			<div className="cws-container">
				<div className="relative cws-booking-form-input-border cws-payment-width">
					<p className="text-center px-1 bg-white text-sm absolute cws-paystack-center inline-block mx-auto">
						secured by{" "}
						<span className="cws-bold-font">paystack</span>{" "}
					</p>
					<div className="flex justify-around cws-payment-prop py-6">
						<Paystack />
						<Master />
						<Visa />
						<Verve />
					</div>
				</div>
			</div>
		</section>
	);
};

export default BookingPayment;
