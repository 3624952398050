import React from "react";
import Input2 from "../../component/building-block/input-2";
import {useMutation} from "../../hooks/useMutation";
import {ApiRequest} from "../../Api/api-request";
import {useSingleState} from "../../hooks/useFetch";
import Loader from "../../component/building-block/loader";
import {useHistory} from "react-router-dom";

const ForgotPassword = () => {
	const email = useSingleState('')
	const history = useHistory()
	const initialise = useMutation({
		prm: () => ApiRequest().post('/password/forgot', {
			email: email.get
		}),
		onS: () => history.push('/login-register')
	})
	return (
		<div className="admin-main-background py-10 max-w-full px-4 min-h-screen w-screen flex justify-center items-center">
			<section
				style={{ width: "36rem", minHeight: "60vh" }}
				className="max-w-xl bg-gray-100 rounded-md shadow-lg"
			>
				<div className="mt-20 px-16 mobiles:px-6">
					<h3 className="text-lg font-bold text-center">
						FORGOT PASSWORD?
					</h3>
					<p className="mt-5 text-center">
						A password recovery mail will be sent to your email
						address
					</p>
					<form onSubmit={e => {e.preventDefault(); initialise.trigger()}} className="mt-10">
						<Input2
							name="email"
							type="email"
							placeholder="Email Address"
							value={email.get}
							onChange={e => email.set(e.currentTarget.value)}
						/>
						<div className="flex mt-10 justify-center relative">
							<button
								type="submit"
								className="inline-block focus:outline-none cursor-pointer w-36 text-center py-3 px-5 admin-gradient-bg text-white rounded-3xl"
							>
								{initialise.loading ? "" : "Submit"}</button>

							<Loader
								style={{
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%,-50%)",
								}}
								show={`${
									initialise.loading ? "" : "opacity-0 force-height-zero"
								}`}
							/>
						</div>

					</form>
				</div>
			</section>
		</div>
	);
};

export default ForgotPassword;
