import React, { useState, useEffect } from "react";

import Payment from "../../../assets/vectors/vector-components/payment";
import { Link, useHistory } from "react-router-dom";

import Eye from "../../../assets/vectors/vector-components/eye";
import User from "../../../assets/vectors/vector-components/user";
import Transaction from "../../../assets/vectors/vector-components/transaction";
import Submenu from "./submenu";
import Cards from "../../../assets/vectors/vector-components/cards";
import Rooms from "../../../assets/vectors/vector-components/rooms";
import AddOns from "../../../assets/vectors/vector-components/add-ons";
import Statistics from "../../../assets/vectors/vector-components/statistics";
import Logout from "../../../assets/vectors/vector-components/logout";
import AdminSidebarUnit from "./admin-sidebar-unit";

const AdminSidebar = () => {
	// const [activeLink, setActiveLink] = useState("overview");
	const history = useHistory();
	const [path, setPath] = useState("");
	const { location } = history;
	// console.log(history);
	useEffect(() => {
		setPath(location.pathname);
		// console.log(location.pathname);
	});

	return (
		<nav className="admin-sidebar pr-10 pt-14 border-gray-100 bg-white">
			<ul>
				<AdminSidebarUnit
					// setActiveLink={setActiveLink}
					activeLink={path}
					text="Overview"
					value="/admin"
					Component={Eye}
					to="/admin"
				/>
				<AdminSidebarUnit
					// setActiveLink={setActiveLink}
					activeLink={path}
					text="User"
					value="/admin/user"
					Component={User}
					to="/admin/user"
				/>

				<Link
					// onClick={() => {
					// 	setActiveLink("transaction");
					// }}
					to="/admin/rooms"
				>
					<li
						className={`${
							[
								"/admin/manage-pricing-plan",
								"/admin/plan-options",
								"/admin/pricing-plan",
								"/admin/rooms",
								"/admin/create-room",
								"/admin/add-ons",
							].includes(path)
								? "admin-gradient-bg-link"
								: "bg-transparent"
						} pl-20 py-4 flex items-center`}
					>
						{/* <SvgImi active={true} /> */}
						<Transaction
							width="20px"
							height="20px"
							active={[
								"/admin/manage-pricing-plan",
								"/admin/plan-options",
								"/admin/pricing-plan",
								"/admin/rooms",
								"/admin/create-room",
								"/admin/add-ons",
							].includes(path)}
						/>
						<span
							className={`ml-4 text-sm ${
								[
									"/admin/manage-pricing-plan",
									"/admin/plan-options",
									"/admin/pricing-plan",
									"/admin/rooms",
									"/admin/create-room",
									"/admin/add-ons",
								].includes(path)
									? "text-white"
									: "admin-gradient-bg-text"
							}`}
						>
							Plans
						</span>
					</li>
					<Submenu active={path} />
				</Link>
				{/* <AdminSidebarUnit
					setActiveLink={setActiveLink}
					activeLink={activeLink}
					text="Plan Options"
					value="plan-options"
					Component={Cards}
					to="/admin/plan-options"
				/> */}
				<AdminSidebarUnit
					// setActiveLink={setActiveLink}
					activeLink={path}
					text="Transactions"
					value="/admin/payments"
					Component={Payment}
					to="/admin/payments"
				/>
				{/* <AdminSidebarUnit
					setActiveLink={setActiveLink}
					activeLink={activeLink}
					text="Rooms"
					value="rooms"
					Component={Rooms}
					to="/admin/rooms"
				/> */}
				{/* <AdminSidebarUnit
					setActiveLink={setActiveLink}
					activeLink={activeLink}
					text="Add-ons"
					value="add-ons"
					Component={AddOns}
					to="/admin/add-ons"
				/> */}
				{/*<AdminSidebarUnit*/}
				{/*	setActiveLink={setActiveLink}*/}
				{/*	activeLink={activeLink}*/}
				{/*	text="Statistics"*/}
				{/*	value="statistics"*/}
				{/*	Component={Statistics}*/}
				{/*	to="/admin"*/}
				{/*/>*/}
				<AdminSidebarUnit
					// setActiveLink={setActiveLink}
					activeLink={path}
					text="Logout"
					value="/admin/logout"
					Component={Logout}
					to="/admin/logout"
				/>
			</ul>
		</nav>
	);
};

export default AdminSidebar;
