import { lazy } from "react";
export const adminRoutesData = [
	{
		path: "/admin/user",
		component: lazy(() => import("../../pages/admin/user")),
	},
	{
		path: "/admin/plan-options",
		component: lazy(() => import("../../pages/admin/plan-options")),
	},
	{
		path: "/admin/view-user/:id",
		component: lazy(() =>
			import("../../component/views/admin/user-component/view-user")
		),
	},
	{
		path: "/admin/pricing-plan",
		component: lazy(() => import("../../pages/admin/admin-pricing-plan")),
	},
	{
		path: "/admin/rooms",
		component: lazy(() => import("../../pages/admin/admin-rooms")),
	},
	{
		path: "/admin/create-room",
		component: lazy(() => import("../../pages/admin/create-room")),
	},
	{
		path: "/admin/logout",
		component: lazy(() => import("../../pages/admin/admin-logout")),
	},
	{
		path: "/admin/payments",
		component: lazy(() => import("../../pages/admin/payments")),
	},
	{
		path: "/admin/pricing-plan-details/:id",
		component: lazy(() =>
			import("../../pages/admin/pricing-price-details")
		),
	},
	{
		path: "/admin/manage-pricing-plan",
		component: lazy(() =>
			import("../../pages/admin/admin-manage-pricing-plan")
		),
	},
	{
		path: "/admin/add-ons",
		component: lazy(
			() => import("../../pages/admin/addon/view-addons")
		)
	},
	{
		path: "/admin",
		component: lazy(() => import("../../pages/admin/dashboard")),
	},
];
