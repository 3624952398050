import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Phone1 } from "../../../../assets/vectors/phone-call.svg";
import { ReactComponent as Message } from "../../../../assets/vectors/email.svg";
import { ReactComponent as Facebook } from "../../../../assets/vectors/facebook.svg";
import { ReactComponent as Instagram } from "../../../../assets/vectors/instagram.svg";
// import { ReactComponent as Google } from "../../../../assets/vectors/google-glass-logo.svg";
import { ReactComponent as Twitter } from "../../../../assets/vectors/twitter.svg";

const CustomerFooter = () => {
	return (
		<footer className="py-20 cws-primary-color border-t-2 border-white">
			<div className="footer cws-container flex mobiles:block justify-between">
				<div className="cws-width-30 mobiles:w-full">
					<ul>
						<h6 className="mb-4 cws-bold-font text-xl text-gray-600">
							INTREPID TECHNOLOGIES
						</h6>
						<span className="mb-3 text-gray-500 block" to="#">
							Unit 11B, Maben Terrace <br />
							LFS Maben Rd, Lekki Lagos Nigeria
						</span>
						<a
							href="mailto:info@intrepid.com.ng"
							className="mb-3 flex items-center text-gray-500"
						>
							<Message className="text-gray-600" />
							<span className="cws-bold-font text-gray-500 block ml-3">
								info@intrepid.com.ng
							</span>
						</a>
						<div className="flex items-center">
							<div>
								<Phone1 className="text-gray-600" />
							</div>
							<div className="ml-3">
								<span className="block cws-bold-font text-gray-400 mb-2">
									+234 803 488 0884
								</span>
								<span className="block cws-bold-font text-gray-400 mb-2">
									+234 805 883 6601
								</span>
							</div>
						</div>
					</ul>
				</div>
				<div className="cws-width-20 mobiles:w-full">
					<ul>
						<h6 className="mb-4 cws-bold-font text-gray-600">Services</h6>
						<Link className="mb-3 text-gray-500 block" to="#">
							Diagram mart
						</Link>
						<Link to="/" className="mb-3 text-gray-500 block">
							Intrepid spaces
						</Link>
					</ul>
				</div>
				<div className="cws-width-20 mobiles:w-full">
					<ul>
						<h6 className="mb-4 cws-bold-font text-gray-600">About Us</h6>
						<a
							className="mb-3 text-gray-500 block"
							href="http://intrepid.com.ng/#aboutus"
						>
							Learn about Interpid
						</a>
					</ul>
				</div>
				<div className="cws-width-20 mobiles:w-3/5">
					<ul>
						<h6 className="mb-4 cws-bold-font text-gray-600">Social</h6>
						<Link className="mb-5 block text-gray-500" to="#">
							Get social with us
						</Link>
						<div className="flex justify-between w-4/5">
							<a href="#" className="cws-social-bg-border">
								<Facebook />
							</a>
							<a
								target="_blank"
								href="https://www.instagram.com/intrepidspaces/
"
								className="cws-social-bg-border"
							>
								<Instagram />
							</a>
							<a
								target="_blank"
								href="https://twitter.com/IntrepidSpaces"
								className="cws-social-bg-border"
							>
								<Twitter />
							</a>
						</div>
					</ul>
				</div>
			</div>
		</footer>
	);
};

export default CustomerFooter;
