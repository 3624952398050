import React from "react";

const BookingHero = () => {
	return (
		<section className="py-28 cws-booking-hero-mt cws-primary-color">
			<div className="cws-container">
				<h2 className="text-6xl cws-bold-font">Make a booking</h2>
			</div>
		</section>
	);
};

export default BookingHero;
