import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/image.png";
import { ReactComponent as Logo2 } from "../../../assets/vectors/Group 2.svg";

const CustomerHeader = () => {
	const [shouldShow, setShouldShow] = useState(false);

	const startRef = useRef(false);
	const navref = useRef();

	// useEffect(() => {
	// 	if (startRef) {
	// 		if (shouldShow) {
	// 			navref.current.classList.add("fadeInLeft");
	// 		}
	// 	}
	// }, [shouldShow]);
	return (
		<nav
			style={{ height: "64px" }}
			className="bg-white fixed flex items-center shadow-md top-0 z-50 right-0 left-0 w-screen py-3"
		>
			<div className="cws-container flex relative justify-between items-center">
				<Link to="/">
					<h1 className="text-2xl mobiles:text-base text-gray-600 cws-bold-font">
						{/* INTREPID TECHNOLOGIES */}
						{/* <img className="h-10" src={logo} alt="" /> */}
						<img
							className="h-12 mobiles:hidden"
							src={
								require("../../../assets/vectors/spaces-logo-intrepid.svg").default
							}
							alt=""
						/>
						<img
							className="h-10 hidden mobiles:block"
							src={
								require("../../../assets/vectors/spaces-logo-intrepid.svg").default
							}
							alt=""
						/>
						{/* <Logo2 /> */}
					</h1>
				</Link>
				<div className="tablets:hidden flex">
					<ul className="flex  items-center ">
						<Link className="block text-gray-500 text-sm pr-5" to="/">
							HOME
						</Link>
						<a
							className="block text-gray-500 text-sm pr-5"
							href="http://intrepid.com.ng/#aboutus"
						>
							WHO WE ARE
						</a>
						<Link className="block text-gray-500 text-sm pr-5" to="#">
							SERVICES
						</Link>
						<a
							className="block text-gray-500 text-sm pr-5"
							href="http://intrepid.com.ng/blog/"
						>
							BLOG
						</a>
						<a
							className="block text-gray-500 text-sm"
							href="http://intrepid.com.ng/contact/"
						>
							CONTACT
						</a>
					</ul>
					<Link
						to="/choose-a-plan"
						className="py-2 ml-8 text-sm rounded text-white cws-red-background px-5"
					>
						BOOK A SPACE
					</Link>
				</div>

				<div
					onClick={() => {
						setShouldShow(!shouldShow);
					}}
					className={`w-8 h-5 tablets:block harmburger ${
						shouldShow ? "active" : ""
					} relative hidden cursor-pointer`}
				>
					<span className="w-full line1 bg-gray-600 absolute block"></span>
					<span className="w-2/5 ml-auto line2 bg-gray-600 absolute block"></span>
					<span className="w-full absolute bg-gray-600 line3 block"></span>
				</div>
			</div>

			<div
				className={`fixed top-0 hidden tablets:block left-0 right-0 bg-white shadow-lg w-screen mobile-nav ${
					shouldShow ? "active" : "inactive"
				}`}
			>
				<ul className="px-6 text-gray-500 py-6">
					<Link
						onClick={() => {
							setShouldShow(false);
						}}
						className="block text-sm mb-5"
						to="/"
					>
						HOME
					</Link>
					<a
						onClick={() => {
							setShouldShow(false);
						}}
						className="block text-sm mb-5"
						href="http://intrepid.com.ng/#aboutus"
					>
						WHO WE ARE
					</a>
					<Link
						onClick={() => {
							setShouldShow(false);
						}}
						className="block text-sm mb-5"
						to="#"
					>
						SERVICES
					</Link>
					<a
						onClick={() => {
							setShouldShow(false);
						}}
						className="block text-sm mb-5"
						href="http://intrepid.com.ng/blog/"
					>
						BLOG
					</a>
					<a
						onClick={() => {
							setShouldShow(false);
						}}
						className="block text-sm mb-5"
						href="http://intrepid.com.ng/contact/"
					>
						CONTACT
					</a>

					<Link
						onClick={() => {
							setShouldShow(false);
						}}
						to="/choose-a-plan"
						className="py-2 block mt-2 text-center w-48 rounded text-white cws-red-background px-5"
					>
						BOOK A SPACE
					</Link>
				</ul>
			</div>
		</nav>
	);
};

export default CustomerHeader;
