import axios from "axios";
import { constant } from "../Config/constant";
import { storageContainsToken, getTokenFromStorage } from "../utils/helpers";

export const ApiRequest = () => {
	const config = { baseURL: constant.baseUrl };
	const instance = axios.create(config);
	return instance;
};
export const ApiRequestWithToken = () => {
	const config = { baseURL: constant.baseUrl };
	if (storageContainsToken()) {
		const token = getTokenFromStorage();
		config.headers = { Authorization: `Bearer ${token}` };
	}
	const instance = axios.create(config);
	return instance;
};

export const register = (formData) => {
	return ApiRequest().post("/register", formData);
};

export const getPlanOptions = () => {
	return ApiRequest().get("/pricing-options");
};

export const getPricingPlans = () => {
	return ApiRequest().get("/pricing-plans");
};

export const getRooms = () => {
	return ApiRequestWithToken().get("/rooms");
};

export const getUsers = () => {
	return ApiRequestWithToken().get("/users");
};

export const getUser = (id) => {
	return ApiRequestWithToken().get(`/user/${id}`);
};

export const getPayments = () => {
	return ApiRequestWithToken().get("/bookings");
};

export const createPlanOptions = (data) => {
	return ApiRequestWithToken().post("/pricing-option", data);
};

export const createRoom = (data) => {
	return ApiRequestWithToken().post("/room", data);
};

export const createPricingPlan = (data) => {
	return ApiRequestWithToken().post("/pricing-plan", data);
};

export const updatePlanOptions = (id, data) => {
	return ApiRequestWithToken().put(`/pricing-option/${id}`, data);
};

export const updatePricingPlan = (id, data) => {
	return ApiRequestWithToken().put(`/pricing-plan/${id}`, data);
};

export const deletePricingPlan = (id) => {
	return ApiRequestWithToken().delete(`/pricing-plan/${id}`);
};

export const deletePlanOptions = (id) => {
	return ApiRequestWithToken().delete(`/pricing-option/${id}`);
};

export const deleteUser = (id) => {
	return ApiRequestWithToken().delete(`/user/${id}`);
};

export const checkLoggedInUser = () => {
	return ApiRequestWithToken().get("/user");
};

export const login = (formData) => {
	return ApiRequest().post("/login", formData);
};
