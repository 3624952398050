import React, { useState } from "react";

const Modal = ({ children, close }) => {

	return (
		<div
			style={{
				background: "rgba(0,0,0,.7)",
				zIndex: "1000",
				maxWidth: "100vw",
				transitionTimingFunction: "ease-in-out",
				transitionProperty: "all",
				transitionDuration: "0.2s",
			}}
			className="py-10 px-4 min-h-screen w-screen flex justify-center items-center fixed top-0 left-0 bottom-0 right-0"
		>
			<section
				style={{ minHeight: "60vh", minWidth: "42rem", width: "auto" }}
				className="bg-white max-w-2xl rounded-lg shadow-2xl p-16 relative"
			>
				<span
					onClick={() => {
						close(false);
					}}
					style={{ right: "25px", top: "15px" }}
					className="absolute cursor-pointer flex justify-center items-center w-8 h-8  rounded-full bg-gray-100 font-bold text-xl text-gray-500"
				>
					x
				</span>
				{children}
			</section>
		</div>
	);
};

export default Modal;
