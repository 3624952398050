import React from "react";

const CwsEverything = () => {
	return (
		<section className="py-20">
			<div className="cws-container">
				<div className="flex mobiles:block items-center">
					<div className="left pr-10 w-1/2 mobiles:w-full">
						<h3 className="text-7xl text-gray-600 mobiles:hidden cws-bold-font">
							Everything you
							<br /> need to get to
							<br />
							work
							<span className="cws-red-text cws-extra-bold-font">.</span>
						</h3>
						<h3 className="text-7xl text-gray-600 hidden mobiles:block mobiles:text-5xl cws-bold-font">
							Everything you <br />
							need to get <br />
							to work
							<span className="cws-red-text cws-extra-bold-font">.</span>
						</h3>
						<p className="text-lg mt-10">
							Our fully-serviced coworking spaces come with all the details taken care
							of, and an experienced on-site team to make sure everything runs
							smoothly. You can simply walk in and get to work, while we look after
							everything else.
						</p>
					</div>
					<div className="right cws-background-image2 w-1/2 mobiles:mt-10  mobiles:w-full h-screen mobile-height"></div>
				</div>
			</div>
		</section>
	);
};

export default CwsEverything;
