import React from "react";

const Eye = ({ active, width, height }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 17 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.5 11C11.25 11 16 6.25 16 6C16 5.75 11.25 1 8.5 1C5.75 1 1 5.875 1 6C1 6.125 5.75 11 8.5 11Z"
				stroke={`${active ? "#ffffff" : "url(#paint0_linear)"}`}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.5 7C9.88071 7 11 5.88071 11 4.5C11 3.11929 9.88071 2 8.5 2C7.11929 2 6 3.11929 6 4.5C6 5.88071 7.11929 7 8.5 7Z"
				stroke={`${active ? "#ffffff" : "url(#paint1_linear)"}`}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<defs>
				<linearGradient
					id="paint0_linear"
					x1="8.35877"
					y1="1"
					x2="6.18485"
					y2="18.6509"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#D4165E" />
					<stop offset="1" stopColor="#2317B1" />
				</linearGradient>
				<linearGradient
					id="paint1_linear"
					x1="4.56653"
					y1="1"
					x2="-3.01224"
					y2="5.18107"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#D4165E" />
					<stop offset="1" stopColor="#2317B1" />
				</linearGradient>
				<linearGradient
					id="paint2_linear"
					x1="12.3445"
					y1="4.67982"
					x2="11.8943"
					y2="10.6186"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#D4165E" />
					<stop offset="1" stopColor="#2317B1" />
				</linearGradient>
				<linearGradient
					id="paint3_linear"
					x1="10.505"
					y1="6.51854"
					x2="10.0994"
					y2="8.03326"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#D4165E" />
					<stop offset="1" stopColor="#2317B1" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default Eye;
