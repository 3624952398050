import React, { Children, createContext, useContext, useReducer } from "react";

export const authAction = "SET_USER";

const initialState = { user: null };

const authReducer = (state, action) => {
	switch (action.type) {
		case authAction: {
			return { ...state, user: action.payload };
		}
		default: {
			return { ...state };
		}
	}
};

const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }) => {
	const [authState, authDispatch] = useReducer(authReducer, initialState);

	const setUserToContext = (payload) => {
		authDispatch({ type: authAction, payload });
	};

	return (
		<AuthContext.Provider
			value={{ state: authState, execute: setUserToContext }}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const ExtractAuthContext = () => {
	return useContext(AuthContext);
};
