import React from "react";

const NewsLetter = () => {
	return (
		<section className="py-32 mt-24 cws-primary-color">
			<div className="cws-container">
				<h3 className="text-center text-4xl text-gray-600 cws-bold-font">
					Join our newsletter
				</h3>
				<p className="mt-10 text-gray-500 text-lg text-center">
					From time to time we send out useful information and tips.
					<br />
					Kindly subscribe to read and share.
				</p>
				<form className="mt-8">
					<div className="p-3 px-8 bg-white flex cws-input-width cws-form-br mx-auto">
						<input
							type="text"
							className="flex-1 mobiles:text-xs focus:outline-none"
							placeholder="Your email here"
						/>
						<input
							// style={{ borderRadius: "40px" }}
							type="submit"
							className="cws-button-m-width mobiles:text-xs cws-bold-font px-7 text-white 
							 cws-red-background mobiles:ml-4 flex items-center justify-center"
							value="SEND NOW"
						/>
					</div>
				</form>
			</div>
		</section>
	);
};

export default NewsLetter;
