import React from "react";
import { Link } from "react-router-dom";

const CwsButton = ({ to, otherStyles, children, ...props }) => {
	return (
		<Link
			className={`cws-button flex items-center justify-center text-center ${otherStyles}`}
			to={to}
			{...props}
		>
			{children}
		</Link>
	);
};

export default CwsButton;
