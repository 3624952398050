import React, { useState } from "react";

import { Redirect } from "react-router-dom";

import LoginForm from "../../component/auth/login-form";
import LoginRegisterTab from "../../component/auth/login-register-tab";
import RegisterForm from "../../component/auth/register-form";
import Loader2 from "../../component/building-block/loader2";
import { ExtractAuthContext } from "../../context/auth-context";
import { storageContainsToken, tokenStillValid } from "../../utils/helpers";

const LoginRegister = () => {
	const [tab, setTab] = useState("login");
	const { state } = ExtractAuthContext();
	if (storageContainsToken() && tokenStillValid()) {
		if (!state.user) {
			return (
				<div style={{ height: "100vh" }} className="flex items-center justify-center">
					<Loader2 />
				</div>
			);
		} else if (state.user.role === "Admin") {
			return <Redirect to={"/admin"} />;
		} else if (state.user.role === "Customer") {
			return <Redirect to={"/customer"} />;
		}
	}

	return (
		<div className="admin-main-background py-10  max-w-full px-4 min-h-screen w-screen flex justify-center items-center">
			<section
				style={{ width: "36rem", minHeight: "90vh" }}
				className="max-w-xl bg-gray-100 rounded-md shadow-lg"
			>
				<LoginRegisterTab setTab={setTab} tab={tab} />
				<div className="h-5 bg-gray-200"></div>
				<div className="pt-14 px-10 mobiles:px-4">
					<LoginForm setTab={setTab} tab={tab} />
					<RegisterForm tab={tab} />
				</div>
			</section>
		</div>
	);
};

export default LoginRegister;
