import React, { useState } from "react";
import { Link } from "react-router-dom";

const Submenu = ({ active }) => {
	// const [current, setCurrent] = useState(null);
	return (
		<ul
			// className={`${
			// 	active === "transaction"
			// 		? "admin-scale-y"
			// 		: "admin-scale-y-init"
			// } pl-20 pt-3`}
			className={`${
				[
					"/admin/manage-pricing-plan",

					"/admin/plan-options",
					"/admin/pricing-plan",
					"/admin/rooms",
					"/admin/create-room",
					"/admin/add-ons",
				].includes(active)
					? "admin-scale-y-init active"
					: "admin-scale-y-init"
			}`}
		>
			<Link to="/admin/rooms">
				<li className="pb-4 flex items-center">
					<span
						style={{ height: "2px" }}
						className={`${
							active === "/admin/rooms" ? "admin-gradient-bg" : "bg-gray-400"
						} block w-4 h-1`}
					></span>
					<span
						className={`${
							active === "/admin/rooms" ? "admin-gradient-bg-text" : "text-gray-400"
						} ml-3 text-sm`}
					>
						View Spaces Types
					</span>
				</li>
			</Link>
			<Link to="/admin/create-room">
				<li className="pb-4 flex items-center">
					<span
						style={{ height: "2px" }}
						className={`${
							active === "/admin/create-room" ? "admin-gradient-bg" : "bg-gray-400"
						} block w-4 h-1`}
					></span>
					<span
						className={`${
							active === "/admin/create-room"
								? "admin-gradient-bg-text"
								: "text-gray-400"
						} ml-3 text-sm`}
					>
						Add Spaces
					</span>
				</li>
			</Link>
			<Link to="/admin/plan-options">
				<li className="pb-4 flex items-center">
					<span
						style={{ height: "2px" }}
						className={`${
							active === "/admin/plan-options" ? "admin-gradient-bg" : "bg-gray-400"
						} block w-4 h-1`}
					></span>
					<span
						className={`${
							active === "/admin/plan-options"
								? "admin-gradient-bg-text"
								: "text-gray-400"
						} ml-3 text-sm`}
					>
						View Plans
					</span>
				</li>
			</Link>
			<Link to="/admin/pricing-plan">
				<li className="flex pb-4 items-center">
					<span
						style={{ height: "2px" }}
						className={`${
							active === "/admin/pricing-plan" ? "admin-gradient-bg" : "bg-gray-400"
						} block w-4`}
					></span>
					<span
						className={`${
							active === "/admin/pricing-plan"
								? "admin-gradient-bg-text"
								: "text-gray-400"
						} ml-3 text-sm`}
					>
						Create Pricing Plan
					</span>
				</li>
			</Link>
			<Link to="/admin/manage-pricing-plan">
				<li className="flex pb-4 items-center">
					<span
						style={{ height: "2px" }}
						className={`${
							active === "/admin/manage-pricing-plan"
								? "admin-gradient-bg"
								: "bg-gray-400"
						} block w-4`}
					></span>
					<span
						className={`${
							active === "/admin/manage-pricing-plan"
								? "admin-gradient-bg-text"
								: "text-gray-400"
						} ml-3 text-sm`}
					>
						Manage Pricing Plan
					</span>
				</li>
			</Link>
			<Link to="/admin/add-ons">
				<li className="flex items-center">
					<span
						style={{ height: "2px" }}
						className={`${
							active === "/admin/add-ons" ? "admin-gradient-bg" : "bg-gray-400"
						} block w-4`}
					></span>
					<span
						className={`${
							active === "/admin/add-ons" ? "admin-gradient-bg-text" : "text-gray-400"
						} ml-3 text-sm`}
					>
						Plan Adds-On
					</span>
				</li>
			</Link>
		</ul>
	);
};

export default Submenu;
