import CustomerBooking from '../../pages/customer/booking';
import ChooseAPlan from '../../pages/customer/choose-plan';
import CustomerHomepage from '../../pages/customer/homepage';

export const routesData = [
    {
        path: "/choose-a-plan",
        component: ChooseAPlan,
    },
    {
        path: "/booking",
        component: CustomerBooking,
    },
    {
        path: "/",
        component: CustomerHomepage,
    },
];
