import React from "react";
import {ApiRequest, ApiRequestWithToken} from "../Api/api-request";

export function useFetch(url, {firstLoad = true, auth = true}={}) {
    const loading = useSingleState(false)
    const result = useSingleState(null)
    const canFetch = useSingleState(firstLoad)
    React.useEffect(() => {
        if (canFetch.get)
            fetchData()
        else
            canFetch.set(true)
    }, [url, firstLoad, auth])
    const fetchData = React.useCallback(() => {
        loading.set(true)
        return callAsync(
            () => auth ? ApiRequestWithToken().get(url) : ApiRequest().get(url),
            (data) => {
                console.log(data)
                result.set(data.data)
            },
            (error) => {
                console.log(error)
            },
            () => loading.set(false)
        )
    }, [url, auth])

    return {
        loading: loading.get,
        data: result.get,
        reFetch: fetchData
    }
}

export const useSingleState = (initialState) => {
    const [data, setData] = React.useState(initialState)
    return {
        get: data,
        set: setData
    }
}


export async function callAsync(callFunction, onSuccess, onError, onComplete) {
    try {
        const response = await callFunction();
        if (onSuccess) {
            onSuccess(response.data);
        }
    } catch (err) {
        if (err.response && !!onError) {
            onError(err.response);
        }
    } finally {
        if (onComplete) {
            onComplete();
        }
    }
}
