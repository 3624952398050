import React from "react";
import { ReactComponent as Printer } from "../../../../assets/vectors/printer.svg";
import { ReactComponent as Private } from "../../../../assets/vectors/private.svg";
import { ReactComponent as Coffee } from "../../../../assets/vectors/coffee-cup.svg";
import { ReactComponent as Seat } from "../../../../assets/vectors/chair.svg";
import { ReactComponent as Meeting } from "../../../../assets/vectors/meeting-room.svg";
import { ReactComponent as Guest } from "../../../../assets/vectors/guest.svg";
import { ReactComponent as Wifi } from "../../../../assets/vectors/wifi.svg";
import { ReactComponent as Parking } from "../../../../assets/vectors/parking.svg";
import { ReactComponent as Training } from "../../../../assets/vectors/presentation.svg";

const CwsFeatures = () => {
	return (
		<section className="py-20 mobiles:py-5">
			<div className="cws-container">
				<div className="flex mobiles:flex-col items-center">
					<div className="left cws-background-image mobiles:mt-8 mobiles:order-2 mobile-height w-1/2 mobiles:w-full h-screen"></div>
					<div className="right w-1/2 mobiles:w-full mobiles:pl-0 pl-10">
						<h3 className="text-7xl mobiles:text-5xl text-gray-600 cws-bold-font">
							Intrepid
							<br /> features
							{/* Co-Working
							<br /> features */}
							<span className="cws-red-text cws-extra-bold-font">.</span>
						</h3>
						<p className="text-lg mt-10">
							Our beautifully-designed coworking spaces are fitted with all you need
							to perform and do business.
						</p>
						<div className="mt-12 grid grid-cols-3 mobiles:grid-cols-2 gap-y-6">
							<div className="flex items-center">
								<div className="cws-features-icon-bg">
									<Printer />
								</div>
								<p className="ml-3 text-sm mobiles:text-xs">Print/Scan</p>
							</div>
							<div className="flex items-center">
								<div className="cws-features-icon-bg">
									<Private />
								</div>
								<p className="ml-3 text-sm mobiles:text-xs">Private office</p>
							</div>
							<div className="flex items-center">
								<div className="cws-features-icon-bg">
									<Coffee />
								</div>
								<p className="ml-3 text-sm mobiles:text-xs">Tea & Coffee</p>
							</div>
							<div className="flex items-center">
								<div className="cws-features-icon-bg">
									<Seat />
								</div>
								<p className="ml-3 text-sm mobiles:text-xs">Dedicated Seat</p>
							</div>
							<div className="flex items-center">
								<div className="cws-features-icon-bg">
									<Meeting />
								</div>
								<p className="ml-3 text-sm mobiles:text-xs">Meeting Room</p>
							</div>
							<div className="flex items-center">
								<div className="cws-features-icon-bg">
									<Guest />
								</div>
								<p className="ml-3 text-sm mobiles:text-xs">Guest Allowed</p>
							</div>
							<div className="flex items-center">
								<div className="cws-features-icon-bg">
									<Wifi />
								</div>
								<p className="ml-3 text-sm mobiles:text-xs">Highspeed Internet</p>
							</div>
							<div className="flex items-center">
								<div className="cws-features-icon-bg">
									<Training />
								</div>
								<p className="ml-3 text-sm mobiles:text-xs">Training Room</p>
							</div>
							<div className="flex items-center">
								<div className="cws-features-icon-bg">
									<Parking />
								</div>
								<p className="ml-3 text-sm mobiles:text-xs">Parking</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CwsFeatures;
