import React from "react";

import { useFetch } from "../../../../hooks/useFetch";
import CwsCard from "./cws-card";

const CwsOptions = ({ optionsRef }) => {
	const { loading, data } = useFetch("/rooms", { auth: false });

	return (
		<section className="pt-32 mobiles:pt-16 mobiles:pb-12 pb-24">
			<div className="cws-container">
				<h2 className="text-5xl text-gray-600 cws-bold-font">
					Intrepid Options
					{/* Co-Working Options */}
					<span className="cws-red-text cws-extra-bold-font">.</span>{" "}
				</h2>
				<h6 className="text-lg text-gray-500  mt-10">
					We offer a number of different coworking options. Compare them below, find out
					more and choose whatʼs right for your business.
				</h6>
				<div
					ref={optionsRef}
					className="grid gap-12 mobiles:gap-8 gap-y-24 mt-20 mobiles:mt-10 grid-cols-3 mobiles:grid-cols-1"
				>
					{(data ?? []).map((room) => (
						<CwsCard
							key={room.id}
							data={{
								id: room.id,
								heading: room.name,
								text: room.description,
								price: "Prices from ₦" + room.base_price,
							}}
						/>
					))}
				</div>
			</div>
		</section>
	);
};

export default CwsOptions;
