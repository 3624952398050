import { useState } from "react";
import { validateRegisterFormData } from "../form-validation/register-validation";
import { toast } from "react-toastify";
import { register } from "../Api/api-request";

export const useForm = (initialState = {}) => {
	const [formData, setFormData] = useState(initialState);
	const [errors, setErrors] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleInputChange = (e) => {
		if (e.target.name === "agree") {
			setFormData({ ...formData, [e.target.name]: e.target.checked });
		} else {
			setFormData({ ...formData, [e.target.name]: e.target.value });
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const validationResult = validate();
		setErrors(validationResult);
		if (Object.keys(validationResult).length > 0) {
			return;
		}
		const userData = formData;
		if (!userData.other_name) {
			delete userData.other_name;
		}
		// console.log(userData);
		setIsSubmitting(true);
		try {
			const response = await register(userData);
			if (response.status === 201) {
				toast.success(response.data.message);
				resetForm();
				setIsSubmitting(false);
			}
		} catch (error) {
			setIsSubmitting(false);

			if (!error.response) {
				toast.error("Please! Check your network and refresh");
			} else if (error.response.status === 422) {
				Object.values(error.response.data.errors)
					.flat()
					.forEach((err) => {
						toast.error(err);
					});
			} else {
				toast.error("Something went wrong! Please try again");
			}
		}
	};

	const validate = () => {
		return validateRegisterFormData(formData);
	};

	const resetForm = () => {
		setFormData({
			...formData,
			name: "",
			first_name: "",
			last_name: "",
			email: "",
			password: "",
			other_name: "",
			password_confirmation: "",
			phone_number: "",
		});
	};

	return {
		formData,
		handleInputChange,
		resetForm,
		errors,
		isSubmitting,
		handleSubmit,
	};
};
