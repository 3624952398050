import React, { Suspense } from "react";

import { Redirect, Route, Switch } from "react-router-dom";

import Loader2 from "../../component/building-block/loader2";
import AdminFooter from "../../component/shared/admin/admin-footer";
import AdminHeader from "../../component/shared/admin/admin-header";
import AdminSidebar from "../../component/shared/admin/admin-sidebar";
import { ExtractAuthContext } from "../../context/auth-context";
import { adminRoutesData } from "../routes-data/admin-routes-data";

const AdminRouteComponent = () => {
	const { state } = ExtractAuthContext();

	console.log(state);

	if (!state.user) {
		return (
			<div style={{ height: "100vh" }} className="flex items-center justify-center">
				<Loader2 />
			</div>
		);
	} else if (state.user.role === "Customer") {
		return <Redirect to={"/customer"} />;
	}

	return (
		<div>
			<AdminHeader />
			<AdminSidebar />

			<main className="admin-sidebar-left-margin admin-main-background px-16 pt-10">
				<Switch>
					<Suspense
						fallback={
							<div
								style={{ height: "70vh" }}
								className="flex items-center justify-center"
							>
								<Loader2 />
							</div>
						}
					>
						{adminRoutesData.map((content) => {
							return (
								<Route path={content.path} exact component={content.component} />
							);
						})}
					</Suspense>
				</Switch>
				<AdminFooter />
			</main>
		</div>
	);
};

export default AdminRouteComponent;
