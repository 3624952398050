import React from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useMutation} from "../../hooks/useMutation";
import {ApiRequest} from "../../Api/api-request";
import {useSingleState} from "../../hooks/useFetch";
import Input from "../building-block/input";
import Loader from "../building-block/loader";
import Modal from "../building-block/modal";

const VerifyEmail = () => {
    const {data} = useParams();
    const {search} = useLocation();
    const showModal = useSingleState(false)
    const token = search.slice(search.indexOf("=") + 1).split("");
    const history = useHistory()
    const resend = useMutation({
        prm: () => ApiRequest().put('verify/resend', {
            email: data
        }),
    })

    const verifyEmail = useMutation({
        prm: () => ApiRequest().post('verify', {
            verification_code: token.join(""),
            email: data
        }),
        onS: () => {
            history.push('/login-register')
        }
    })
    console.log(data, token);
    return (
        <>
            {showModal.get && <ResendVerification closeModal={() => showModal.set(false)}/>}
            <div
                className="admin-main-background py-10 max-w-full px-4 min-h-screen w-screen flex justify-center items-center">
                <section
                    style={{width: "36rem", minHeight: "60vh"}}
                    className="max-w-xl bg-gray-100 rounded-md shadow-lg"
                >
                    <div className="mt-20 px-16 mobiles:px-6">
                        <h3 className="text-lg font-bold text-center">
                            Confirm the 6-digit verification code we sent via
                            Email
                        </h3>
                        <p className="text-center block text-gray-600 mt-2 mb-5">
                            (We want to make sure it's you)
                        </p>
                        <div
                            style={{maxWidth: "15rem"}}
                            className="w-60 mx-auto grid grid-cols-6 h-10 gap-2"
                        >
                            {token.map((data, i) => {
                                return (
                                    <div
                                        key={`token${i}`}
                                        className="bg-white shadow-md"
                                    >
                                        <p className="border-0 rounded-sm  admin-gradient-bg-text font-bold h-full bg-white flex justify-center items-center border-gray-300">
                                            {data}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="mt-10">
                            {token?.length === 6 && <div className="mt-10 flex justify-center">
                                <button
                                    onClick={verifyEmail.trigger}
                                    className="inline-block cursor-pointer w-48 text-center py-3 px-2 admin-gradient-bg text-white rounded-3xl"
                                >Verify
                                </button>
                            </div>
                            }
                        </div>
                        <div className="mt-5">
                            <h6 className="text-gray-500 text-center">
                                Didn't receive the code?
                            </h6>
                            <div className="flex justify-center">
                                <button
                                    onClick={() => resend.trigger()}
                                    className="bg-none font-bold cursor-pointer text-green-500 text-center outline-none focus:outline-none">
                                    Resend Code
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default VerifyEmail;

function ResendVerification({closeModal}) {
    const email = useSingleState('')
    const resend = useMutation({
        prm: () => ApiRequest().put('verify/resend', {
            email: email.get
        }),
        onS: () => closeModal()
    })

    return (
        <Modal close={closeModal}>
            <h6 className="mt-5 text-center font-bold text-gray-600 text-lg">
                Enter email
            </h6>
            <form onSubmit={e => {
                e.preventDefault();
                resend.trigger()
            }} className="mt-12 px-4">
                <div>
                    <Input label="Email address" placeholder={"Input your email"} type="email"
                           onChange={e => email.set(e.currentTarget.value)} value={email.get}
                           error={resend.validationError?.email} required/>
                </div>
                <div className="flex mt-10 justify-center relative">
                    <button
                        type="submit"
                        className="inline-block focus:outline-none cursor-pointer w-36 text-center py-3 px-5 admin-gradient-bg text-white rounded-3xl"
                    >
                        {resend.loading ? "" : "Resend"}</button>

                    <Loader
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                        }}
                        show={`${
                            resend.loading ? "" : "opacity-0 force-height-zero"
                        }`}
                    />
                </div>
            </form>
        </Modal>
    )
}
