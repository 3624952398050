import React from "react";

const Logout = ({ active, width, height }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9C17 13.4183 13.4183 17 9 17Z"
				stroke={`${active ? "#ffffff" : "url(#paint0_linear)"}`}
			/>
			<path
				d="M17 9H6.71429"
				stroke={`${active ? "#ffffff" : "url(#paint1_linear)"}`}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M9 6.71428L6.25714 8.99999L9 11.2857"
				stroke={`${active ? "#ffffff" : "url(#paint2_linear)"}`}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<defs>
				<linearGradient
					id="paint0_linear"
					x1="9"
					y1="1"
					x2="13.1532"
					y2="26.2914"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#D4165E" />
					<stop offset="1" stop-color="#2317B1" />
				</linearGradient>
				<linearGradient
					id="paint1_linear"
					x1="11.8571"
					y1="9"
					x2="11.8831"
					y2="10.6229"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#D4165E" />
					<stop offset="1" stop-color="#2317B1" />
				</linearGradient>
				<linearGradient
					id="paint2_linear"
					x1="7.62857"
					y1="6.71428"
					x2="9.5181"
					y2="13.6181"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#D4165E" />
					<stop offset="1" stop-color="#2317B1" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default Logout;
