import React from "react";

const AdminFooter = () => {
	return (
		<div className="mt-16">
			<h2 className="admin-gradient-bg-text2 text-center pb-5 text-xs">
				Copyright &copy; Touchcore. All Rights Reserved
			</h2>
		</div>
	);
};

export default AdminFooter;
