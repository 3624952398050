import React from "react";

const User = ({ active, width, height }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.05833 9.10833C9.98333 9.10833 11.6167 6.54167 11.6167 4.55833C11.6167 2.575 9.98333 1 8.05833 1C6.075 1 4.5 2.575 4.5 4.55833C4.5 6.54167 6.075 9.10833 8.05833 9.10833Z"
				stroke={`${active ? "#ffffff" : "url(#paint0_linear)"}`}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M9.75 8.5834L10.9167 10.3334C12.8417 10.3334 14.4167 11.9084 14.4167 13.8334L15 15.5834H1L1.58333 13.8334C1.58333 11.9084 3.15833 10.3334 5.08333 10.3334L6.25 8.5834"
				stroke={`${active ? "#ffffff" : "url(#paint1_linear)"}`}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M5.08319 10.3334C7.00819 11.9084 8.99152 11.9084 10.9165 10.3334"
				stroke={`${active ? "#ffffff" : "url(#paint2_linear)"}`}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<defs>
				<linearGradient
					id="paint0_linear"
					x1="8.05833"
					y1="1"
					x2="5.67894"
					y2="13.7174"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#D4165E" />
					<stop offset="1" stop-color="#2317B1" />
				</linearGradient>
				<linearGradient
					id="paint1_linear"
					x1="8"
					y1="8.5834"
					x2="7.07323"
					y2="19.8707"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#D4165E" />
					<stop offset="1" stop-color="#2317B1" />
				</linearGradient>
				<linearGradient
					id="paint2_linear"
					x1="7.99986"
					y1="10.3334"
					x2="7.93616"
					y2="12.2489"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#D4165E" />
					<stop offset="1" stop-color="#2317B1" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default User;
