import React from 'react'
import {useSingleState} from "../../hooks/useFetch";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useMutation} from "../../hooks/useMutation";
import {ApiRequest} from "../../Api/api-request";
import Input2 from "../../component/building-block/input-2";
import Loader from "../../component/building-block/loader";


export function PasswordReset(){
    const {data} = useParams()
    const location = useLocation()
    const token = (new URLSearchParams(location.search)).get('token')
    const newPassword = useSingleState('')
    const confirmPassword = useSingleState("")
    const history = useHistory()
    const resetPassword = useMutation({
        prm: () => ApiRequest().put('/password/reset', {
            email: data,
            token: token,
            password: newPassword.get,
            password_confirmation: confirmPassword.get
        }),
        onS: () => history.push('/login-register')
    })
    return (
        <div>

            <div className="admin-main-background py-10 max-w-full px-4 min-h-screen w-screen flex justify-center items-center">
                <section
                    style={{ width: "36rem", minHeight: "60vh" }}
                    className="max-w-xl bg-gray-100 rounded-md shadow-lg"
                >
                    <div className="mt-20 px-16 mobiles:px-6">
                        <h3 className="text-lg font-bold text-center">
                            RESET YOUR PASSWORD
                        </h3>
                        <p className="mt-5 text-center">

                        </p>
                        <form onSubmit={e => {e.preventDefault(); resetPassword.trigger()}} className="mt-10">
                            <Input2
                                name="password"
                                type="password"
                                placeholder="New Password"
                                value={newPassword.get}
                                onChange={e => newPassword.set(e.currentTarget.value)}
                                error={resetPassword.validationError?.password}
                                required
                            />
                            <Input2
                                required
                                name="confirmpassword"
                                type="password"
                                placeholder="Confirm New Password"
                                value={confirmPassword.get}
                                onChange={e => confirmPassword.set(e.currentTarget.value)}
                                error={resetPassword.validationError?.password_confirmation}
                            />
                            <div className="flex mt-10 justify-center relative">
                                <button
                                    type="submit"
                                    className="inline-block focus:outline-none cursor-pointer w-36 text-center py-3 px-5 admin-gradient-bg text-white rounded-3xl"
                                >
                                    {resetPassword.loading ? "" : "Submit"}</button>

                                <Loader
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%,-50%)",
                                    }}
                                    show={`${
                                        resetPassword.loading ? "" : "opacity-0 force-height-zero"
                                    }`}
                                />
                            </div>

                        </form>
                    </div>
                </section>
            </div>
        </div>
    )
}
