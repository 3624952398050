import React from 'react';
import Loader2 from "./loader2";

const SelectInput = ({ options, label, onChange, name, value }) => {
    return (
        <div>
            {label && <label className="block mb-2">{label}l</label>}
            <div className="relative">
                <select
                    style={{ borderWidth: "2px", zIndex: "0" }}
                    name={name}
                    className="w-full pl-4 py-3 focus:outline-none relative border-gray-500 cws-select"
                    onChange={onChange}
                    value={value}
                    id=""
                >
                    {options.map(({ value, text }) => {
                        return <option value={value}>{text}</option>;
                    })}
                </select>
                <span className="absolute cws-select-triangle"></span>
            </div>
        </div>
    );
};

export default SelectInput;


export const SelectInput3 = ({ options, label, onChange, loading, name, value, required = true }) => {
    return (
        <div>
            <label
                className={`inline-block ${
                    label
                        ? "visible p-4 py-0 text-gray-400"
                        : "hidden opacity-0 h-0 m-0 p-0"
                }`}
            >
                {label}
            </label>
            <div className="relative">
                <select
                    style={{ borderWidth: "2px", zIndex: "0" }}
                    name={name}
                    required={required}
                    className="mt-3 px-5 pl-8 admin-input focus:outline-none border border-gray-200 block w-full cws-select bg-transparent"
                    onChange={onChange}
                    value={value}
                    id=""
                >
                    {options.map(({ value, text }) => {
                        return <option value={value}>{text}</option>;
                    })}
                </select>
                <span className="absolute cws-select-triangle"></span>
                {loading && (
                    <div
                        style={{
                            position: "absolute",
                            width: "42px",
                            height: "42px",
                            zIndex: "2",
                            top: "50%",
                            left: "5%",
                            transform: "translateY(-30%)",
                        }}
                    >
                        <Loader2 />
                    </div>
                )}
            </div>
        </div>
    );
};
