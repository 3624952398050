import React from "react";

const TermsOfUse = () => {
	return (
		<div className="cws-container pt-12 pb-8">
			<h3 className="text-center font-bold text-gray-800 mb-3">
				Virtual Office & Mail Handling Services
			</h3>
			<h6 className="text-gray-800 text-center font-bold">Terms and Conditions</h6>
			<p className="mt-4 text-black leading-loose">
				Subject to the customer (“the Customer”) observing the Terms and Conditions set out
				below, Intrepid Spaces agrees to provide Virtual Office & Mail Handling Services
				(“the Services”) to the Customer for the term (“the Term”) at 11B Maben Terrace, LFS
				Maben Rd. Lekki, Lagos State, Nigeria upon receipt of the payment (“the Payment”).
			</p>
			<p className="mt-4 leading-loose">
				1. The Services provided by Intrepid Spaces will consist of the followingservices in
				accordance with the scale of charges: <br /> <br /> i. Intrepid Spaces will provide
				the Customer with a virtual address at the Premises. <br />
				ii. The Customer can collect mail from the reception desk during the Premises
				opening hours of 08.30 –17.30 on working days.
				<br />
				iii. The Customer may use the Services address provided by Intrepid Spaces as the
				Registered Address at Corporate Affairs Commission (CAC), provided that the Customer
				complies with the Companies and Allied Matters Act and CAC regulations and pays an
				additional annual Registered Company Address Fee. <br />
				iv. Intrepid Spaces will receive on the Customer’s behalf all pre-paid mail
				addressed to the Customer and will store in the Customers allocated mailbox
				onreception
			</p>
			<p className="mt-4 leading-loose">
				2. Payment for the Services (“the Payment”) is payable in full, in advance. The
				Customer may terminate the Services at any time in writing. There will be no refunds
				of thePayment or any type of compensation or damagesto Customers for early
				termination. The Services may not be transferred to any other personal, business or
				corporatename.
			</p>
			<p className="mt-4 leading-loose">
				3. Intrepid Spaces may in its absolute discretion refuse to accept delivery of any
				item for any reason, including, without limitation,that <br />
				<br />
				i. There is no or insufficient prepaid postage; or <br />
				ii. In Intrepid Spaces’s opinion, delivery of the item is in breach of Condition 7;
				or <br />
				iii. The Customer is using the Services for the delivery of parcels which are unable
				to fit into a mail box. <br />
				iv. The mail box is full; or <br />
				v. Any item received is addressed to an individual or business name that are
				mismatched or not listed as a mailing name;or <br />
				vi. The Services is being used for the storage or delivery of illegal or perishable
				items, or items ofvalue.
			</p>
			<p className="mt-4 leading-loose">
				4. If the Customer fails to make any Payment, any mail received after the expiry of
				the existing agreement may, at Intrepid Spaces’s absolute discretion, be retained
				for up to 30 days pending Payment. In this event a Late Payment Fee for the Services
				will becharged.
			</p>
			<p className="mt-4 leading-loose">
				5. If the Customer uses the Services address within any advertising undertaken via
				third parties including the not limited to printed or online directories, search
				engine portals or on the Customer’s own or any other websites, Payment will be
				required to cover the entire advertising period for which the Services address
				isdisplayed.
			</p>
			<p className="mt-4 leading-loose">
				6. If any Payments or other sums are outstandingtoIntrepid Spaces, Intrepid Spaces
				shall have a lien over all uncollected items until such Payments are duly made.
			</p>
			<p className="mt-4 leading-loose">
				7. The Customerundertakes and shall not <br />
				<br /> i. use the Services for any purpose which in Intrepid Spaces’ considered
				opinion may be deemed to be illegal, misleading or antisocial and if it does so it
				acknowledges that Intrepid Spaces may report the same to any relevant authority;and{" "}
				<br />
				ii. send or deliver or permit to be sent or delivered to the Premises any illegal,
				inflammable, incendiary, defamatory, obscene, dangerous or bulky object ormaterial.
			</p>
			<p className="mt-4 leading-loose">
				8. If the Customer is in breach of condition 7 of these Terms and Conditions, then
				Intrepid Spaces may terminate the Services with immediate effect. In this instance
				there will be{" "}
				<span className="font-bold">
					no refund of thePayment or any type of compensation or damages
				</span>{" "}
				to Customer.
			</p>
			<p className="mt-4 leading-loose">
				9. The Customer authorises Intrepid Spaces and any of its representatives to sign at
				their discretion on the Customer’s behalf for any deliveries addressed to the
				Customer’s virtual address.
			</p>
			<p className="mt-4 leading-loose">
				10. If the Customer fails to collect any mail within one month of notice to that
				effect being given (“the Notice Date”) then the Customer hereby authorises Intrepid
				Spaces in its absolute discretion, either to destroy such items or to return them
				without further notice, at the Customer’s expense.
			</p>
			<p className="mt-4 leading-loose">
				11. The Customer may check by telephone or email to see if they have any mail.
				Intrepid Spaces is not obliged to open and read mail or relay sender details.
			</p>
			<p className="mt-4 leading-loose">
				12. Intrepid Spaces shall not be liable for any indirect or consequential loss,
				including loss of profit, nor for any liabilities, costs, claims, demands or
				expenses arising from any event beyond its control including, but not limited to,
				any loss, damage, delay or mis delivery on the part of carrier.
			</p>
			<p className="mt-4 leading-loose">
				13. In order to activate the Services the Customer is required toprovide to the
				satisfaction of Intrepid Spaces: <br />
				<br />
				i. the full name, address and telephone number of all persons for whom mail is to be
				received or held;and <br />
				ii. the nature of business to be undertaken or reason for use of the Services where
				rental is not for business purposes. A business can be defined as an activity
				involving the sale of goods and/or services;and <br />
				iii. two (2) original, verifiable forms of identification, or suitably certified
				copies, for all persons for whom mail is to be received, held or forwarded, of a
				type approved by Intrepid Spaces, including a ‘proof of identity’ and a ‘proof of
				address’.
			</p>
			<p className="mt-4 text-center font-bold leading-loose">
				I hereby acknowledge that I have read and understood all of the terms and conditions
				contained in these Terms and Conditions and further agree to be bound to these Terms
				and Conditions regarding my participation in and use of the Services.
			</p>
		</div>
	);
};

export default TermsOfUse;
