import { constant } from '../Config/constant';

export const storageContainsToken = () => {
    return !!localStorage.getItem(constant.tokenName);
};

export const getTokenFromStorage = () => {
    return localStorage.getItem(constant.tokenName);
};

export const getExpiryTimeFromStorage = () => {
    return localStorage.getItem(constant.expiryName);
};

export const setTokenToStorage = (token) => {
    localStorage.setItem(constant.tokenName, token);
};

export const setExpiryDateToStorage = (expiryDate) => {
    localStorage.setItem(constant.expiryName, expiryDate);
};

export const removeTokenFromStorage = () => {
    localStorage.removeItem(constant.tokenName);
};

export const removeExpiryDateFromStorage = () => {
    localStorage.removeItem(constant.expiryName);
};

export const tokenStillValid = () => {
    const expiryStatus =
        new Date().getTime() < new Date(getExpiryTimeFromStorage()).getTime();
    console.log("status = " + expiryStatus);
    return expiryStatus;
};

export const parseErrorToArray = (error) => {
    const arr = [];
    Object.values(error).forEach((v) => {
        arr.push(...v);
    });
    return arr;
};

export const getApiErrorMessage = (err) => {
    const error = err?.data ?? err;
    const firstError = parseErrorToArray(error?.errors ?? {})?.[0];
    return firstError ?? error?.message ?? "An error occurred";
};
