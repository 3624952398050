import React from "react";

import { Route, Switch } from "react-router-dom";

import { ReactComponent as Whatsapp } from "../../assets/vectors/whatsapp.svg";

import CustomerFooter from "../../component/shared/customer/footer/footer";
import CustomerHeader from "../../component/shared/customer/header";
import NewsLetter from "../../component/shared/customer/newsletter";
import { routesData } from "../routes-data/customer-routes-data";

const CustomerRouteComponent = () => {
	return (
		<div>
			<CustomerHeader />
			<button
				style={{
					zIndex: "200",
					bottom: "8%",
					right: "5%",
				}}
				className="fixed"
			>
				<a
					target="_blank"
					rel="noopener noreferrer"
					className="w-16 block mobiles:w-10 mobiles:h-10 h-16"
					href="https://wa.me/2349077777398"
				>
					<img
						src={require("../../assets/vectors/whatsapp.svg").default}
						className="block ripple w-full"
						alt=""
					/>
					{/* <Whatsapp /> */}
				</a>
			</button>

			<section>
				<Switch>
					{routesData.map((data) => {
						return <Route path={data.path} component={data.component} />;
					})}
				</Switch>
				<NewsLetter />
			</section>
			<CustomerFooter />
		</div>
	);
};

export default CustomerRouteComponent;
