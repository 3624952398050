import "react-datetime/css/react-datetime.css";

import React, { useRef } from "react";

import dayjs from "dayjs";
import Datetime from "react-datetime";
import { useHistory } from "react-router-dom";

import { ApiRequest } from "../../../../Api/api-request";
import { callAsync, useFetch, useSingleState } from "../../../../hooks/useFetch";
import Loader2 from "../../../building-block/loader2";
import SelectInput from "../../../building-block/select-input";
import PlanCard from "./plan-card";

const PlanSection = () => {
	const summaryRef = useRef();
	// const immediate = useRef(false);

	const { loading, data } = useFetch("/rooms", { auth: false });
	const defaultDate = [dayjs().add(1, "days"), dayjs().add(4, "days")];
	const location = useHistory();
	const checking = useSingleState();
	const availabilityResult = useSingleState({});
	const selectedRoom = useSingleState(null);
	const selectedPlan = useSingleState(null);
	const [date, setDate] = React.useState(defaultDate);
	const startDate = useSingleState(dayjs().add(1, "hours").toISOString());
	const endDate = useSingleState(dayjs().add(3, "hours").toISOString());
	const clear = () => {
		availabilityResult.set({});
		selectedPlan.set(null);
	};

	console.log(selectedPlan.get);
	const handleCheck = () => {
		checking.set(true);
		console.log(dayjs(startDate.get).format("YYYY-MM-DD HH:mm"));
		callAsync(
			() =>
				ApiRequest().post(`/book/${selectedRoom.get.id}/availability`, {
					pricing_plan: selectedPlan.get.id,
					start_date: dayjs(startDate.get).format("YYYY-MM-DD HH:mm"),
					end_date: dayjs(endDate.get).format("YYYY-MM-DD HH:mm"),
				}),
			(data) => {
				availabilityResult.set(data.data);
			},
			() => {},
			() => checking.set(false)
		);
	};
	const handlePay = () => {
		localStorage.setItem(
			"book_data",
			JSON.stringify({
				start_date: dayjs(startDate.get).format("YYYY-MM-DD HH:mm"),
				end_date: dayjs(endDate.get).format("YYYY-MM-DD HH:mm"),
				pricing_plan: selectedPlan.get.id,
				room: selectedRoom.get.id,
			})
		);
		location.push("/booking");
	};
	React.useEffect(() => {
		const url = new URLSearchParams(window.location.search);
		console.log(url.get("room"));
		if (data?.length > 0) {
			if (url.get("room")) {
				selectedRoom.set(data.find((d) => d.id === url.get("room")));
			} else {
				selectedRoom.set(data[0]);
			}
		}
	}, [data]);

	return (
		<section className="py-12 pt-32 mobiles:pb-0 bg-white">
			<div className="cws-booking-hero-ml mobiles:ml-0 tablets:block flex">
				<div className="left w-3/5  mobiles:pr-0 tablets:w-screen pr-10 pt-8">
					<div className="flex justify-between mobiles:block mobile-padding items-center">
						<h2 className="text-6xl mobiles:text-5xl text-gray-600 cws-bold-font">
							CHOOSE A PLAN
						</h2>
						<div className="w-48 mobiles:w-56 mobiles:mt-5">
							<SelectInput
								onChange={(e) => {
									selectedRoom.set(
										data.find((v) => v.id === e.currentTarget.value)
									);
									clear();
								}}
								value={selectedRoom.get?.id}
								options={[
									...(data?.map((d) => ({
										value: d.id,
										text: d.name,
									})) ?? []),
								]}
								name="room"
							/>
						</div>
					</div>
					<p className="text-lg mobile-padding mt-8">{selectedRoom.get?.description}</p>
					<div className="grid mt-12 grid-cols-2 mobile-padding mobiles:grid-cols-1 gap-x-12 gap-y-40 mobiles:gap-y-44">
						{selectedRoom.get?.pricing_plans.map((pp) => (
							<PlanCard
								selected={selectedPlan.get?.id === pp.id}
								onSelect={() => selectedPlan.set(pp)}
								plan={pp}
								key={pp.id}
								summaryRef={summaryRef}
							/>
						))}
					</div>
				</div>
				<div
					ref={summaryRef}
					className="right  w-2/5 tablets:w-full cws-booking-form-bg pl-10 mobile-padding2 mobiles:py-16 mobiles:mt-44  pb-10"
				>
					{selectedPlan.get && (
						<>
							<h2 className="text-5xl cws-bold-font mt-36 mobiles:mt-0">
								ORDER SUMMARY
							</h2>
							<h5 className="hidden mobiles:block cws-bold-font pt-4">
								Plan Name: &nbsp;&nbsp;{selectedPlan.get.name}{" "}
								<span>{`(${selectedPlan.get.type})`}</span>
							</h5>
							<h5 className="text-2xl mt-8">Price:</h5>
							<p className="text-lg">
								₦{selectedPlan.get.rate} per {selectedPlan.get.rate_type}
							</p>
							<div className="mt-10">
								<div className="space-y-5">
									<div>
										<h5 className="mb-1">Start date:</h5>
										<div>
											<Datetime
												value={dayjs(startDate.get).toDate()}
												onChange={(date) => {
													// console.log(date.toISOString());
													startDate.set(date.toISOString());
												}}
												isValidDate={(current) =>
													dayjs(current).isAfter(dayjs())
												}
												className={
													"border-2 w-56 pl-2 h-12 border-gray-500"
												}
												inputProps={{
													className:
														"h-12 mr-2 w-full focus:outline-none bg-transparent",
												}}
											/>
										</div>
									</div>
									<div>
										<h5 className="mb-1">End date:</h5>
										<div>
											<Datetime
												value={dayjs(endDate.get).toDate()}
												onChange={(date) => endDate.set(date.toISOString())}
												isValidDate={(current) =>
													dayjs(current).isAfter(dayjs(startDate.get))
												}
												className={
													"border-2 w-56 pl-2 h-12 border-gray-500"
												}
												inputProps={{
													className:
														"h-12 mr-2 w-full focus:outline-none bg-transparent",
												}}
											/>
										</div>
									</div>
								</div>

								{availabilityResult?.get.available === false && (
									<span className="block text-red-500 text-sm font-bold">
										Unavailable
									</span>
								)}
								{availabilityResult?.get.available === true && (
									<span className="block text-green-500 text-sm font-bold">
										Availble
									</span>
								)}
								{checking.get ? (
									<Loader2 show={true} />
								) : (
									<button
										onClick={handleCheck}
										className="cws-red-background px-10 mt-4 cws-bold-font text-white cws-button-plan flex items-center justify-center"
									>
										Check Availability
									</button>
								)}
							</div>

							{availabilityResult?.get.available === true && (
								<>
									<h5 className="text-2xl mt-8">Total:</h5>
									<p className="text-lg mb-12">₦{availabilityResult.get.price}</p>
									<button
										onClick={handlePay}
										className="cws-red-background cws-bold-font text-white cws-button-plan flex items-center justify-center"
									>
										Pay
									</button>
								</>
							)}
						</>
					)}
				</div>
			</div>
		</section>
	);
};

export default PlanSection;
